import { resourceNames } from 'constants';
import { requireResourceImgs, requireToolImgs } from 'game-utils';
import NiceModal from '@ebay/nice-modal-react';

import styles from './worker-modal.module.scss';

import Blocker from 'pages/game/modal/blocker';
import MouseFollow from 'pages/game/modal/mouse-follow';
import PanelBoard from 'pages/game/modal/panel-board';
import LevelBar from './level-bar';
import ResourceIndent from './resource-indent';
import BoostButton from 'pages/game/modal/pool/boost-button';

const resourceImgs = requireResourceImgs();
const toolImgs = requireToolImgs();

function Header({ text }) {
  return (
    <div className={styles['header']}>
      <hr/>
      <span>{text}</span>
      <hr/>
    </div>
  );
}

function WorkerModal({
  fallbackMousePosition,
  fixedPosition,
  freezePosition,
  level,
  currentExp,
  targetExp,
  isLevelUpDisabled,
  onClickLevelUp,
  resourceAmounts,
  toolType,
  toolTier,
  onHover,
}) {
  const handleHover = isHovered => {
    if (onHover) {
      onHover(isHovered);
    }
  };
  const toolImg = toolType && toolTier ? toolImgs[toolType][toolTier] : null;
  return (
    <Blocker isNonBlocking={true} keepMounted={true}>
      <MouseFollow
        fallbackMousePosition={fallbackMousePosition}
        mouseOffset={{ x: 30, y: -165 }}
        fixedPosition={fixedPosition}
        freezePosition={freezePosition}
      >
        <PanelBoard
          className={styles['container']}
          panelClassName={styles['panel']}
          hasDropShadow={true}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          <LevelBar
            level={level}
            currentExp={currentExp}
            targetExp={targetExp}
            isLevelUpDisabled={isLevelUpDisabled}
            onClickLevelUp={onClickLevelUp}
          />
          <Header text="Resources" />
          <div className={styles['indents']}>
            {resourceNames.map(name => {
              return (
                <ResourceIndent
                  key={name}
                  className={styles['indent']}
                  imgSrc={resourceImgs[name]}
                  imgAlt={name}
                  value={resourceAmounts[name] ?? 0}
                />
              );
            })}
          </div>
          <Header text="Boosts" />
          <div className={styles['buttons']}>
            <BoostButton
              className={styles['button']}
              imgSrc={toolImg?.src}
              imgAlt={toolImg?.alt}
              label="Tool"
              isSmall={true}
              isDisabled={true}
            />
            <BoostButton
              className={styles['button']}
              isSmall={true}
              isBlocked={true}
              isDisabled={true}
            />
            <BoostButton
              className={styles['button']}
              isSmall={true}
              isBlocked={true}
              isDisabled={true}
            />
          </div>
        </PanelBoard>
      </MouseFollow>
    </Blocker>
  );
}

export default NiceModal.create(WorkerModal);
