import { resourceNames } from 'constants';
import { requireResourceImgs } from 'game-utils';
import styles from './resource-board.module.scss';

import Board from 'pages/game/modal/board';
import Panel from 'pages/game/modal/panel';
import Toggle from './toggle';

const resourceImgs = requireResourceImgs();

function ResourceBoard({ selected, onChange }) {
  return (
    <Board className={styles['container']}>
      <Panel className={styles['panel']} >
        {resourceNames.map(name => {
          return (
            <Toggle
              key={name}
              className={styles['toggle']}
              imgSrc={resourceImgs[name]}
              name={name}
              isChecked={name === selected}
              onChange={() => onChange ? onChange(name) : null}
            />
          );
        })}
      </Panel>
    </Board>
  );
}

export default ResourceBoard;
