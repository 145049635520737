import styles from './stats-panel.module.scss';
import Panel from 'pages/game/modal/panel';
import Plate from 'pages/game/modal/plate';

import emptyStarImg from 'assets/images/game/market/star-empty.png';
import fullStarImg from 'assets/images/game/market/star-full.png';
import iImg from 'assets/images/game/i.png';

function Star({ isFull }) {
  return (
    <img
      className={styles['star']}
      src={isFull ? fullStarImg : emptyStarImg}
      alt={isFull ? 'Full star' : 'Empty star'}
    />
  );
}

function Stars() {
  return (
    <div className={styles['stars']}>
      {Array(4).fill(null).map((v, i) => {
        return <Star key={i} isFull={true} />;
      })}
      <Star key="5" isFull={false} />
    </div>
  );
}

function Header() {
  return (
    <div className={styles['header']}>
      <div className={styles['header-info']}>
        <span>Knights sword</span>
        <span>Epic</span>
      </div>
      <div className={styles['header-meta']}>
        <Stars />
        <button className={styles['info-button']}>
          <img src={iImg} alt="i" />
        </button>
      </div>
    </div>
  );
}

function Table1() {
  return (
    <table>
      <thead>
        <tr>
          <th>Attack</th>
          <th></th>
          <th>Defense</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>+38</td>
          <td>Stab</td>
          <td>+2</td>
        </tr>
        <tr>
          <td>+4</td>
          <td>Slash</td>
          <td>+1</td>
        </tr>
        <tr>
          <td>+32</td>
          <td>Magic</td>
          <td>+358</td>
        </tr>
      </tbody>
    </table>
  );
}

function Table2() {
  return (
    <table>
      <tbody>
        <tr>
          <td>Strength</td>
          <td>42</td>
        </tr>
        <tr>
          <td>Range damage</td>
          <td>8</td>
        </tr>
        <tr>
          <td>Magic damage</td>
          <td>35</td>
        </tr>
      </tbody>
    </table>
  );
}

function Content() {
  return (
    <>
      <Header />
      <hr/>
      <div className={styles['stats']}>
        <Table1 />
        <Table2 />
      </div>
    </>
  );
}

function EmptyPlate({ message }) {
  return (
    <Plate className={styles['empty-plate']} hasGleam={true}>
      <span className="border-3d">{message}</span>
    </Plate>
  );
}

function StatsPanel({ isEmpty, emptyMessage }) {
  return (
    <Panel className={styles['container']} hasOutline={false}>
      {isEmpty ? <EmptyPlate message={emptyMessage} /> : <Content />}
    </Panel>
  );
}

export default StatsPanel;
