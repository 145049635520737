import { useModal } from '@ebay/nice-modal-react';
import styles from './modal.module.scss';

import Blocker from './blocker';
import CloseButton from './close-button';

function Modal({ className, title, onUnmount, children }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  const modal = useModal();
  return (
    <Blocker onClick={modal.hide} onUnmount={onUnmount}>
      <div className={classNames.join(' ')}>
        <span className={styles['title']}>{title}</span>
        <CloseButton
          className={styles['close-button']}
          onClick={modal.hide}
        />
        {children}
      </div>
    </Blocker>
  );
}

export default Modal;
