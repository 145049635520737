import { resourceNames, toolTypes, toolTiers } from 'constants';
import { hasOwnProperty } from 'utils';

export const requireResourceImgs = () => {
  const output = {};
  resourceNames.forEach(name => {
    output[name] = require(`assets/images/game/resources/${name}.png`);
  });
  return output;
};

export const requireToolImgs = () => {
  const output = {};
  toolTypes.forEach(type => {
    toolTiers.forEach(tier => {
      if (!hasOwnProperty(output, type)) {
        output[type] = {};
      }
      output[type][tier] = {
        src: require(`assets/images/game/tools-large/${type}/${tier}-cropped.png`),
        alt: `${tier} ${type}`,
      };
    });
  });
  return output;
};

export const requireToolIcons = () => {
  const output = {};
  toolTypes.forEach(type => {
    output[type] = require(`assets/images/game/pool/tools/${type}.png`);
  });
  return output;
};

export const sumNftResources = (nft) => {
  let sum = 0;
  resourceNames.forEach(resourceName => {
    sum += Number(nft[`res_${resourceName}`]) ?? 0;
  });
  return sum;
};

export const poolToResourceName = (pool) => {
  switch (pool) {
    case 'mining': return 'ore';
    case 'hunting': return 'hides';
    case 'woodcutting': return 'wood';
    case 'farming': return 'corn';
    case 'fishing': return 'fish';
    //case 'pvp': return null;
  }
};

export const poolToToolType = (pool) => {
  switch (pool) {
    case 'mining': return 'pickaxe';
    case 'hunting': return 'crossbow';
    case 'woodcutting': return 'axe';
    case 'farming': return 'sickle';
    case 'fishing': return 'rod';
    case 'pvp': return 'sword';
  }
};
