import styles from './glow.module.scss';

function Glow({ id, className, pathD, ...props }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <svg id={id} className={classNames.join(' ')} preserveAspectRatio="none" {...props}>
      <g filter={`url(#${id}-filter)`} mask={`url(#${id}-mask)`}>
        <use xlinkHref={`#${id}-path`} fill="black"/>
      </g>
      <use xlinkHref={`#${id}-path`} fill="none" stroke="#edc608" strokeWidth="2px"/>
      <defs>
        <filter id={`${id}-filter`} x="0" y="0" width="100%" height="100%" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix values="0 0 0 0 0.929412 0 0 0 0 0.776471 0 0 0 0 0.0313726 0 0 0 1 0"/>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1711_68"/>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1711_68" result="shape"/>
        </filter>
        <mask id={`${id}-mask`}>
          <rect width="100%" height="100%" fill="white"/>
          <use xlinkHref={`#${id}-path`} fill="black"/>
        </mask>
        <path id={`${id}-path`} fillRule="evenodd" clipRule="evenodd" d={pathD}/>
      </defs>
    </svg>
  );
}

export default Glow;
