import styles from './cta-button.module.scss';

function CtaButton({
  className,
  isSecondary,
  isTertiary,
  isMint,
  isDisabled,
  children,
  onClick
}) {
  const classNames = [styles['container']];
  if (isSecondary) {
    classNames.push(styles['container--secondary']);
  }
  if (isTertiary) {
    classNames.push(styles['container--tertiary']);
  }
  if (isMint) {
    classNames.push(styles['container--mint']);
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <button
        className={styles['button']}
        onClick={onClick}
        disabled={isDisabled}
      >
        <div className={styles['button-inner']}>
          {children}
        </div>
      </button>
    </div>
  );
}

export default CtaButton;
