import { useUser } from 'hooks/query';
import { resourceNames } from 'constants';
import styles from './table.module.scss';

import Indent from 'pages/game/modal/panel/indent';

import goldImg from 'assets/images/game/gold.png';

const resourceImgs = {};
resourceNames.forEach(name => {
  const filename = name === 'hides' ? `${name}-side` : name;
  resourceImgs[name] = require(`assets/images/game/resources/${filename}.png`);
});

function Row({ imgSrc, imgAlt, available, price }) {
  return (
    <tr className={styles['row']}>
      <td>
        <Indent className={styles['indent']}>
          <div className={styles['img-container']}>
            <img className="contained" src={imgSrc} alt={imgAlt} />
          </div>
        </Indent>
      </td>
      <td>
        <Indent className={styles['indent']}>
          <span>{available ? Number(available).toLocaleString() : '-'}</span>
        </Indent>
      </td>
      <td>
        <Indent className={styles['indent']}>
          <span>{price ? Number(price).toLocaleString() : '-'}</span>
        </Indent>
      </td>
    </tr>
  );
}

function Table({ price }) {
  const user = useUser();
  return (
    <table className={styles['container']}>
      <thead>
        <tr className={styles['header']}>
          <th></th>
          <th>Available Balance</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        {resourceNames.map(name => {
          return (
            <Row
              key={name}
              imgSrc={resourceImgs[name]}
              imgAlt={name}
              available={user.data ? user.data[`res_${name}`] : 0}
              price={price ? price[name] : null}
            />
          );
        })}
        <Row
          imgSrc={goldImg}
          alt="Gold"
          available={user.data ? user.data.gold : 0}
          price={price ? price['gold'] : null}
        />
      </tbody>
    </table>
  );
}

export default Table;
