import { useTools } from 'hooks/query';
import { requireToolImgs } from 'game-utils';
import styles from './tool-box.module.scss';

import Panel from 'pages/game/modal/panel';

import closeImg from 'assets/images/game/pool/blocked.png';

const toolImgs = requireToolImgs();

function Tool({ imgSrc, imgAlt, onClick }) {
  return (
    <button className={styles['tool']} onClick={() => onClick({ imgSrc, imgAlt })} >
      <div className={styles['tool-inner']}>
        <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={imgAlt} />
        </div>
      </div>
    </button>
  );
}

function ClearButton({ onClick }) {
  // Just re-using tool style for now, as this has not been properly designed yet.
  return (
    <button className={styles['tool']} onClick={onClick} >
      <div className={styles['tool-inner']}>
        <div className={styles['img-container']}>
          <img className="contained" src={closeImg} alt="Close" />
        </div>
      </div>
    </button>
  );
}

function ToolBox({
  className,
  pool,
  isVisible,
  canClear,
  onClickTool,
  onClickClear,
}) {
  const tools = useTools(pool);
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <Panel
      className={classNames.join(' ')}
      hasCorners={false}
      hasBorder={false}
      isVisible={isVisible}
    >
      <div className={styles['list']}>
        <div className={`${styles['list-inner']} contained scrollbar`}>
          {tools.data ? tools.data.filter(t => !t.nft_id).map((data) => {
            const img = toolImgs[data.type][data.tier];
            return (
              <Tool
                key={data.id}
                imgSrc={img.src}
                imgAlt={img.alt}
                onClick={() => onClickTool(data.id)} />
            );
          }) : null}
          {canClear && <ClearButton onClick={onClickClear} />}
        </div>
      </div>
    </Panel>
  );
}

export default ToolBox;
