import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useResourcesBuy, useResourcesRates, useResourcesSell, useUser } from 'hooks/query';
import NiceModal from '@ebay/nice-modal-react';
import { clamp } from 'utils';
import { resourceNames } from 'constants';
import styles from './market-resources-modal.module.scss';

import Modal from 'pages/game/modal';
import ResourceBoard from './resource-board';
import ControlBoard from './control-board';

import SwapConfirmModal from './swap-confirm';
import LoadingModal from 'pages/game/modal/loading';
import ActionResultModal from 'pages/game/modal/action-result';

function MarketResourcesModal() {
  const [resourceName, setResourceName] = useState(resourceNames[0]);
  const [amount, setAmount] = useState(0);
  const [isReverse, setReverse] = useState(false);

  const user = useUser();
  const rates = useResourcesRates();
  const userGold = Number(user?.data.gold ?? 0);
  const userResource = Number(user?.data[`res_${resourceName}`] ?? 0);
  const maxAmount = isReverse ? userGold : userResource;
  const resourceRate = rates.data ? (rates.data[resourceName] ?? 0) : 0;
  const taxRate = rates.data?.tax ?? 0;

  const validateAmount = (value) => {
    return value != '' && value >= 0 && value <= maxAmount;
  };
  const sanitiseAmount = (value) => {
    return value != '' ? clamp(value, 0, maxAmount) : value;
  };
  const setAmountProportion = (value) => {
    setAmount(value * maxAmount);
  };

  // Ensure displayed amount reflects user
  useEffect(() => {
    setAmount(sanitiseAmount(amount))
  }, [user.data]);

  // Revert to max when changing resource or trade direction
  useEffect(() => {
    setAmount(sanitiseAmount(maxAmount))
  }, [resourceName, isReverse]);

  const queryClient = useQueryClient();
  const buyer = useResourcesBuy();
  const seller = useResourcesSell();

  const handleClickSwap = (out, _in) => {
    NiceModal.show(SwapConfirmModal, {
      outImgSrc: out.imgSrc,
      outImgAlt: out.name,
      outValue: out.amount,
      inImgSrc: _in.imgSrc,
      inImgAlt: _in.name,
      inValue: _in.amount,
      taxRate: taxRate,
    }).then(() => {
      NiceModal.show(LoadingModal);
      const options = {
        onSuccess: () => {
          queryClient.invalidateQueries(['user']);
          NiceModal.show(ActionResultModal);
        },
        onError: e => alert(e.response.data.message),
        onSettled: () => NiceModal.hide(LoadingModal),
      };
      if (isReverse) {
        buyer.mutate({
          type: resourceName,
          gold: out.amount,
        }, options);
      } else {
        seller.mutate({
          type: resourceName,
          amount: out.amount,
        }, options);
      }
    });
  };

  const isLoading = (
    user.isLoading ||
    rates.isLoading ||
    buyer.isLoading ||
    seller.isLoading
  );

  return (
    <Modal className={styles['container']} title="Resources" >
      <ResourceBoard
        selected={resourceName}
        onChange={setResourceName}
      />
      <ControlBoard
        resourceName={resourceName}
        resourceRate={resourceRate}
        isReverse={isReverse}
        amount={amount}
        isSwapDisabled={!validateAmount(amount) || isLoading}
        onAmountChange={value => setAmount(sanitiseAmount(value))}
        onClickHalf={() => setAmountProportion(0.5)}
        onClickMax={() => setAmountProportion(1)}
        onToggleDirection={() => setReverse(!isReverse)}
        onClickSwap={handleClickSwap}
      />
    </Modal>
  )
}

export default NiceModal.create(MarketResourcesModal);
