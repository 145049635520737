import styles from './fire-ring.module.scss';

function FireRing({ id, className, animate }) {
  const classNames = [styles['container']];
  if (className) classNames.push(className);
  if (animate) classNames.push(styles['animate']);
  const pathId = `${id}-path`;
  const turbId = `${id}-turb`;
  const bgColor = 'gold';
  const fgColor = 'lightyellow';
  return (
    <div className={classNames.join(' ')}>
      <svg fill="none" viewBox="0 0 363 153" width="363" filter="blur(1px)">
        <g filter={`url(#${turbId})`}>
          <use
            className={styles['path']}
            href={`#${pathId}`}
            x="-3" y="-3"
            stroke={bgColor}
            strokeWidth="8"
            filter="url(#glow)"
          />
        </g>
        <defs>
          <path id={pathId} d="M54.5 51.5 57 51h249.5l2 .5 2 1.5 1 1.5.5 2.5v39.5l-.5 2-1.5 2-1.5 1-2.5.5H56.5l-2-.5-2-1.5-1-1.5L51 96V56.5l.5-2 1.5-2 1.5-1Z"/>
          <filter id="glow" x="-100%" y="-100%" height="300%" width="300%" colorInterpolationFilters="sRGB">
            <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor={bgColor} floodOpacity="1">
              <animate attributeName="stdDeviation" dur="5s" values="10;14;8;4;8;10" repeatCount="indefinite"/>
            </feDropShadow>
          </filter>
          <filter id={turbId} x="-100%" y="-100%" height="300%" width="300%" colorInterpolationFilters="sRGB">
            <feTurbulence baseFrequency="0.02" numOctaves="5" seed="2" stitchTiles="noStitch">
              <animate attributeName="baseFrequency" dur="2s" values="0.025;0.015" repeatCount="indefinite"/>
            </feTurbulence>
            <feDisplacementMap in="SourceGraphic" scale="14" />
          </filter>
        </defs>
      </svg>
      <svg fill="none" viewBox="0 0 363 153" width="363">
        <use className={styles['path']} href={`#${pathId}`} stroke={fgColor} strokeWidth="4"/>
      </svg>
    </div>
  );
}

export default FireRing;
