import React, { useEffect, useRef } from 'react';
import { useWindowScale } from 'hooks/util';
import { assignToRefs } from 'utils';
import styles from './scaled.module.scss';

const Scaled = React.forwardRef(({ className, children, modifier, ...props }, ref) => {
  const internalRef = useRef(null);
  const scale = (modifier ?? 1) * useWindowScale();
  useEffect(() => {
    if (internalRef.current) {
      internalRef.current.style.setProperty('--scale', scale);
    }
  }, [scale]);
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <div
      ref={el => assignToRefs(el, internalRef, ref)}
      className={classNames.join(' ')}
      {...props}
    >
      {children}
    </div>
  );
});

export default Scaled;
