import styles from './resource-indent.module.scss';

import Indent from 'pages/game/modal/panel/indent';

function ResourceIndent({ className, imgSrc, imgAlt, value }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <Indent className={classNames.join(' ')}>
      <div className={styles['img-container']}>
        <img
          className="contained"
          src={imgSrc}
          alt={imgAlt}
        />
      </div>
      <span>{value}</span>
    </Indent>
  );
}

export default ResourceIndent;
