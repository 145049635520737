import styles from './character.module.scss';

import levelUpImg from 'assets/images/game/pool/level-up.png';

function Character({ imgSrc, imgAlt, canLevelUp }) {
  return (
    <div className={`${styles['container']} border-3d`}>
      <div className={styles['img-container']}>
        <img className="contained" src={imgSrc} alt={imgAlt} />
      </div>
      {!!canLevelUp && <img
        className={styles['level-up-img']}
        src={levelUpImg}
        alt="Level up"
      />}
    </div>
  );
}

export default Character;
