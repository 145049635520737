import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { cacheImages } from 'utils';
import './App.css';

import Game from './pages/game';

const imgsToPreload = [
  require('assets/images/game/map-bg.gif'),
  require('assets/images/game/hud/menu/boards/cracked.png'),
  require('assets/images/game/hud/menu/boards/snow.png'),
  require('assets/images/game/hud/menu/boards/wood.png'),
  require('assets/images/game/hud/menu/boards/tentacle.png'),
  require('assets/images/game/hud/menu/boards/blood.png'),
  require('assets/images/game/modal-bg.jpg'),
  require('assets/images/game/board-bg.jpg'),
  require('assets/images/game/gleam-white.png'),
  require('assets/images/game/gleam-brown.png'),
  require('assets/images/game/panel/bg.jpg'),
  require('assets/images/game/resources/hides.png'),
  require('assets/images/game/resources/corn.png'),
  require('assets/images/game/resources/ore.png'),
  require('assets/images/game/resources/fish.png'),
  require('assets/images/game/resources/wood.png'),
];

function App({ onLoad }) {
  const [isLoaded, setLoaded] = useState(false);
  useEffect(() => {
    cacheImages(imgsToPreload, () => {
      if (onLoad) {
        setLoaded(true);
        onLoad();
      }
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Game isAppLoaded={isLoaded} />} />
    </Routes>
  );
}

export default App;
