import React, { useEffect, useState } from 'react';
import styles from './chained-board.module.scss';

import Chain from './chain';
import Board from './board';

const ChainedBoard = React.forwardRef(({
  boardStyles,
  isVisible,
  children,
  includePlayButton,
  onClickPlay,
}, ref) => {
  ref.current = ref.current ?? {
    leftChainLinks: [],
    rightChainLinks: [],
    boards: [],
  };
  const addToArr = (el, arr) => {
    if (el && !arr.includes(el)) {
      arr.push(el);
    }
  };

  const [animatePlayButtonFire, setAnimatePlayButtonFire] = useState(false);
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setAnimatePlayButtonFire(true);
      }, 650);
    }
  }, [isVisible]);

  const classNames = [styles['container']];
  if (isVisible) classNames.push(styles['visible']);
  /**
   * A copy of the board only showing the hooks is rendered first,
   * so the hooks are behind the chain, and the visible board (the
   * 2nd one) is in front of the chain.
   */
  return (
    <div className={classNames.join(' ')}>
      <Board
        ref={el => addToArr(el, ref.current.boards)}
        styles={boardStyles}
        onlyHooks={true}
      />
      <Chain linkRef={el => addToArr(el, ref.current.leftChainLinks)} />
      <Chain linkRef={el => addToArr(el, ref.current.rightChainLinks)} />
      <Board
        ref={el => addToArr(el, ref.current.boards)}
        styles={boardStyles}
        hideHooks={true}
        includePlayButton={includePlayButton}
        animatePlayButtonFire={animatePlayButtonFire}
        onClickPlay={onClickPlay}
      >
        {children}
      </Board>
    </div>
  );
});

export default ChainedBoard;
