import React from 'react';
import styles from './panel-board.module.scss';

import Board from 'pages/game/modal/board';
import Panel from 'pages/game/modal/panel';

import cornerTlImg from 'assets/images/game/panel-board/corner-tl.png';
import cornerTrImg from 'assets/images/game/panel-board/corner-tr.png';
import cornerBrImg from 'assets/images/game/panel-board/corner-br.png';
import cornerBlImg from 'assets/images/game/panel-board/corner-bl.png';

function Title({ text, backgroundColor }) {
  const classNames = [
    styles['title-container'],
    styles[`title-container--${backgroundColor}`],
    'border-3d'
  ];
  return (
    <div className={classNames.join(' ')}>
      <span>{text}</span>
    </div>
  );
}

function Corner({ imgSrc }) {
  return <img className={styles['corner']} src={imgSrc} alt="Corner" />;
}

function Corners() {
  return (
    <>
      <Corner imgSrc={cornerTlImg} />
      <Corner imgSrc={cornerTrImg} />
      <Corner imgSrc={cornerBrImg} />
      <Corner imgSrc={cornerBlImg} />
    </>
  );
}

const PanelBoard = React.forwardRef(({
  className,
  title,
  titleBackgroundColor,
  hasDropShadow,
  panelClassName,
  panelHasGleam,
  panelHasBorder,
  hasNoPanel,
  children,
  onMouseEnter,
  onMouseLeave
}, ref) => {
  const hasTitle = title;
  const classNames = [styles['container']];
  if (hasTitle) {
    classNames.push(styles['has-title']);
  }
  if (hasDropShadow) {
    classNames.push(styles['drop-shadow']);
  }
  if (className) {
    classNames.push(className);
  }
  const panelClassNames = [styles['panel']];
  if (panelClassName) {
    panelClassNames.push(panelClassName);
  }
  return (
    <Board
      ref={ref}
      className={classNames.join(' ')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {hasTitle && <Title text={title} backgroundColor={titleBackgroundColor} />}
      <div className={styles['inner']}>
        {hasNoPanel ? children : <Panel
          className={panelClassNames.join(' ')}
          hasCorners={false}
          hasBrightGleam={panelHasGleam}
          hasBorder={panelHasBorder ?? true}
        >
          {children}
        </Panel>}
      </div>
      <Corners />
    </Board>
  );
});

export default PanelBoard;
