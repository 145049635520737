import styles from './labelled-indent.module.scss';
import Indent from 'pages/game/modal/panel/indent';

function LabelledIndent({ className, label, children }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <Indent className={styles['indent']}>
        {children}
      </Indent>
      <span>{label}</span>
    </div>
  );
}

export default LabelledIndent;
