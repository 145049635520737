import { requireResourceImgs } from 'game-utils';
import styles from './control-board.module.scss';

import Board from 'pages/game/modal/board';
import Panel from 'pages/game/modal/panel';
import Plate from 'pages/game/modal/plate';
import QuantityBox from './quantity-box';
import CtaButton from 'pages/game/modal/cta-button';

import switchImg from 'assets/images/game/market/switch.png';
import goldImg from 'assets/images/game/gold.png';

const resourceImgs = requireResourceImgs();

function Separator() {
  return <div className={styles['separator']}></div>;
}

function ControlBoard({
  resourceName,
  resourceRate,
  isReverse,
  amount,
  isSwapDisabled,
  onAmountChange,
  onClickHalf,
  onClickMax,
  onToggleDirection,
  onClickSwap,
}) {
  const out = {
    name: isReverse ? 'Gold' : resourceName,
    imgSrc: isReverse ? goldImg : resourceImgs[resourceName],
    amount: amount,
    value: 1,
  };
  const _in = {
    name: isReverse ? resourceName : 'Gold',
    imgSrc: isReverse ? resourceImgs[resourceName] : goldImg,
    amount: isReverse ? amount / resourceRate : amount * resourceRate,
    value: isReverse ? 1 / resourceRate : resourceRate,
  };
  return (
    <Board className={styles['container']}>
      <Plate className={styles['plate']} hasBrightGleam={true} hasOutline={true} >
        <Panel className={styles['panel']} hasGreyOutline={true} >
          <QuantityBox
            className={styles['quantity-box']}
            imgSrc={out.imgSrc}
            imgAlt={out.name}
            value={out.amount}
            isCondensed={true}
          />
          <Separator />
          <div className={styles['exchange-rate']}>
            <span className={`${styles['exchange-rate-inner']} border-3d`}>
              <span>{out.value.toLocaleString()}</span>&nbsp;{out.name}
              &nbsp;=&nbsp;
              <span>{_in.value.toLocaleString()}</span>&nbsp;{_in.name}
            </span>
          </div>
          <Separator />
          <QuantityBox
            className={styles['quantity-box']}
            imgSrc={_in.imgSrc}
            imgAlt={_in.name}
            isCondensed={true}
            value={_in.amount}
          />
        </Panel>
        <Panel className={styles['panel']} hasGreyOutline={true} >
          <QuantityBox
            className={styles['quantity-box']}
            imgSrc={out.imgSrc}
            imgAlt={out.name}
            isInput={true}
            value={out.amount}
            onChange={onAmountChange}
            onClickHalf={onClickHalf}
            onClickMax={onClickMax}
          />
          <Separator />
          <div className={styles['center-buttons']}>
            <CtaButton
              className={styles['center-button']}
              onClick={onToggleDirection}
            >
              <img src={switchImg} alt="Switch" />
            </CtaButton>
            <CtaButton
              className={styles['center-button']}
              isSecondary={true}
              isDisabled={isSwapDisabled}
              onClick={() => onClickSwap(out, _in)}
            >Swap</CtaButton>
          </div>
          <Separator />
          <QuantityBox
            className={styles['quantity-box']}
            imgSrc={_in.imgSrc}
            imgAlt={_in.name}
            value={_in.amount}
          />
        </Panel>
      </Plate>
    </Board>
  );
}

export default ControlBoard;
