import { useEffect, useRef } from 'react';
import { useNftsLevelUp } from 'hooks/query';
import { useQueryClient } from '@tanstack/react-query';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useMousePosition, useWindowScale } from 'hooks/util';
import { modalScale, resourceNames } from 'constants';
import { poolToToolType, sumNftResources } from 'game-utils';
import styles from './workers-panel.module.scss';

import Panel from 'pages/game/modal/panel';
import Worker from './worker';
import WorkerModal from '../worker-modal';
import LoadingModal from 'pages/game/modal/loading';
import ActionResultModal from 'pages/game/modal/action-result';

function Tab({ name, value, isChecked, onChange }) {
  return (
    <label className={styles['tab']}>
      <input
        type="radio"
        className="hidden"
        name="tab"
        value={value}
        checked={isChecked}
        onChange={onChange}
      />
      <div className={`${styles['tab-inner']} border-3d`}>
        <span>{name}</span>
      </div>
    </label>
  );
}

function WorkersPanel({
  className,
  pool,
  tab,
  onChangeTab,
  datas,
  checkedId,
  onChangeCheckedId,
}) {
  // ——— Floating worker modal ———

  const queryClient = useQueryClient();
  const leveller = useNftsLevelUp();
  
  const modal = useModal();
  const mousePosition = useMousePosition();
  const scale = modalScale * useWindowScale();
  const workerModal = useModal(WorkerModal);
  const lastHoveredWorkerRef = useRef({
    id: null,
    isHovered: false,
    rect: null,
  });
  const workerModalHoveredRef = useRef(false);

  const updateWorkerModal = () => {
    const isVisible = (lastHoveredWorkerRef.current.isHovered || 
      workerModalHoveredRef.current) && 
      tab === 'staked' &&
      modal.visible;

    if (!isVisible) {
      workerModal.hide();
      return;
    }

    const hoveredData = datas.find(data => {
      return data.id === lastHoveredWorkerRef.current.id;
    });
    const resourceAmounts = {};
    resourceNames.forEach(name => {
      resourceAmounts[name] = hoveredData[`res_${name}`];
    });
    const tool = hoveredData.tools[0] ?? null;

    workerModal.show({
      fallbackMousePosition: mousePosition,
      fixedPosition: { x: lastHoveredWorkerRef.current.rect.left - 373 * scale },
      freezePosition: workerModalHoveredRef.current,
      level: hoveredData[`lvl_${pool}`],
      currentExp: hoveredData[`exp_${pool}`],
      targetExp: hoveredData.exp_threshold,
      isLevelUpDisabled: !hoveredData.can_level_up || leveller.isLoading,
      onClickLevelUp: () => {
        NiceModal.show(LoadingModal);
        leveller.mutate({
          id: hoveredData.id,
          pool: pool,
        }, {
          onSuccess: () => {
            queryClient.invalidateQueries(['nfts']);
            NiceModal.show(ActionResultModal);
          },
          onError: e => alert(e.response.data.message),
          onSettled: () => NiceModal.hide(LoadingModal),
        });
      },
      onHover: isHovered => {
        workerModalHoveredRef.current = isHovered;
        updateWorkerModal();
      },
      resourceAmounts: resourceAmounts,
      toolType: tool?.type,
      toolTier: tool?.tier,
    });
  };

  const handleWorkerHover = (id, isHovered, rect) => {
    lastHoveredWorkerRef.current = { id, isHovered, rect };
    updateWorkerModal();
  };

  useEffect(() => {
    updateWorkerModal();
  }, [scale, datas]);

  // ———————————————————————


  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <Panel className={classNames.join(' ')}>
      <div className={styles['tabs']}>
        <Tab
          name="Working"
          value="staked"
          isChecked={tab === 'staked'}
          onChange={onChangeTab}
        />
        <Tab
          name="Available workers"
          value="available"
          isChecked={tab === 'available'}
          onChange={onChangeTab}
        />
      </div>
      <div className={styles['list']}>
        <div className={`${styles['list-inner']} border-3d scrollbar`}>
          {datas.filter(data => {
            return data.pool === (tab === 'staked' ? pool : null);
          }).map((data, index) => {
            return (
              <Worker
                key={data.id}
                className={styles['worker']}
                alternateColor={index % 2}
                name={data.name}
                imgSrc={data.img_src}
                isStaked={!!data.pool}
                toolType={poolToToolType(pool)}
                resources={sumNftResources(data)}
                level={data[`lvl_${pool}`]}
                canLevelUp={data.can_level_up}
                onHover={(isHovered, rect) => handleWorkerHover(data.id, isHovered, rect)}
                isChecked={data.id === checkedId}
                onCheckboxChange={() => onChangeCheckedId(data.id)}
              />
            );
          })}
        </div>
      </div>
    </Panel>
  );
}

export default WorkersPanel;
