import { useQuery, useMutation } from '@tanstack/react-query';
import { useAuthContext } from 'auth-context';
import axios from 'axios';

axios.defaults.baseURL = 'https://nft-game-api.pugs.ly/api';
axios.defaults.headers.common['Accept'] = 'application/json';


// ——— Auth ———

export const useRegister = () => {
  return useMutation(async (params) => {
    const { data } = await axios.post('/auth/register', params);
    return data;
  });
};

export const useLogin = () => {
  return useMutation(async (params) => {
    const { data } = await axios.post('/auth/login', params);
    return data;
  });
};

export const useLogout = () => {
  return useMutation(async (params) => {
    const { data } = await axios.post('/auth/logout', params);
    return data;
  });
};

export const useUser = () => {
  return useAuthedQuery(['user'], '/user');
};


// ——— NFTs ———

export const useNftsAvailability = () => {
  return useAuthedQuery(['nfts-availability'], '/nfts/availability');
};

export const useNftsMint = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put('/nfts/mint', params);
    return data;
  });
};

export const useNfts = (params) => {
  const { isAuthed } = useAuthContext();
  return useQuery(['nfts', params.pool], async () => {
    const { data } = await axios.get('/nfts', {
      params: {
        pool: params.pool
      }
    });
    return data;
  }, {
    enabled: isAuthed,
    refetchInterval: 3000,
  });
};

export const useNftsStake = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put(`/nfts/stake/${params.id}`, params);
    return data;
  });
};

export const useNftsGather = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put(`/nfts/gather/${params.id}`, params);
    return data;
  });
};

export const useNftsUnstake = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put(`/nfts/unstake/${params.id}`, params);
    return data;
  });
};

export const useNftsLevelUp = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put(`/nfts/level-up/${params.id}`, params);
    return data;
  });
};


// ——— Tools ———

export const useToolsAvailability = (type, tier) => {
  return useAuthedQuery(['tools-availability', type, tier], '/tools/availability', {
    type: type,
    tier: tier,
  });
};

export const useToolsMint = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put('/tools/mint', params);
    return data;
  });
};

export const useTools = (pool) => {
  const { isAuthed } = useAuthContext();
  return useQuery(['tools', pool], async () => {
    const { data } = await axios.get('/tools', {
      params: {
        pool: pool
      }
    });
    return data;
  }, {
    enabled: isAuthed,
  });
};

export const useToolsAssign = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put(`/tools/assign/${params.id}`, params);
    return data;
  });
};

export const useToolsUnassign = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put(`/tools/unassign/${params.id}`);
    return data;
  });
};


// ——— Resources ———

export const useResourcesRates = () => {
  return useAuthedQuery(['resources-rates'], '/resources/rates');
};

export const useResourcesBuy = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put('/resources/buy', params);
    return data;
  });
};

export const useResourcesSell = () => {
  return useMutation(async (params) => {
    const { data } = await axios.put('/resources/sell', params);
    return data;
  });
};


// ——— Misc ———

export const useAuthedQuery = (key, url, params) => {
  const { isAuthed } = useAuthContext();
  return useQuery(key, async () => {
    const { data } = await axios.get(url, { params });
    return data;
  }, {
    enabled: isAuthed,
  });
};
