import styles from './indent.module.scss';

function Indent({ className, isBlack, children }) {
  const classNames = [styles['container'], 'border-3d'];
  if (isBlack) {
    classNames.push(styles['container--black']);
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <div className={`${styles['inner']} border-3d`}></div>
      {children}
    </div>
  );
}

export default Indent;
