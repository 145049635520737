import NiceModal from '@ebay/nice-modal-react';
import { roundToDec } from 'utils';
import styles from './resources-confirm-modal.module.scss';

import { ConfirmModal } from 'pages/game/modal/confirm';
import Panel from 'pages/game/modal/panel';
import Indent from 'pages/game/modal/panel/indent';

function ResourcesConfirmModal({
  imgSrc,
  imgAlt,
  amount,
  numNfts,
  totalNfts
}) {
  return (
    <ConfirmModal className={styles['container']}>
      <span className={styles['title']}>Safe Resources Gather</span>
      <Panel className={styles['panel']}>
        <span>You will collect</span>
        <table className={styles['table']}>
          <tbody>
            <tr>
              <td>
                <Indent className={styles['indent']}>
                  {imgSrc && <div className={styles['indent-img-container']}>
                    <img className="contained" src={imgSrc} alt={imgAlt} />
                  </div>}
                  <span>{amount}</span>
                </Indent>
              </td>
              <td>
                <Indent className={styles['indent']}>
                  <span>-20%</span>
                </Indent>
              </td>
            </tr>
            <tr>
              <td>
                <Indent className={styles['indent']}>
                  <span>=</span>
                  <span>{roundToDec(0.8 * amount, 1)}</span>
                </Indent>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className={styles['panel-footer']}>
          <span>From:</span>
          <span>{numNfts}/{totalNfts} NFTs</span>
        </div>
      </Panel>
    </ConfirmModal>
  );
}

export default NiceModal.create(ResourcesConfirmModal);
