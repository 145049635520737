import NiceModal from '@ebay/nice-modal-react';
import styles from './swap-confirm-modal.module.scss';

import { ConfirmModal } from 'pages/game/modal/confirm';
import Panel from 'pages/game/modal/panel';
import Indent from 'pages/game/modal/panel/indent';

import swapArrowImg from 'assets/images/game/market/swap-arrow.png';

function ResourceIndent({ imgSrc, imgAlt, value }) {
  return (
    <Indent className={styles['resource-indent']}>
      <div className={styles['img-container']}>
        <img className="contained" src={imgSrc} alt={imgAlt} />
      </div>
      <span>{Number(value).toLocaleString()}</span>
    </Indent>
  );
}

function SwapArrow() {
  return (
    <img
      className={styles['swap-arrow']}
      src={swapArrowImg}
      alt="Swap arrow"
    />
  );
}

function TaxIndent({ proportion, value }) {
  const content = [];
  if (Number(proportion) > 0) {
    content.push(`Tax: ${100 * proportion}%`, value);
  } else {
    content.push('Tax free');
  }
  return (
    <Indent className={styles['tax-indent']}>
      {content.map((v, i) => {
        return <span key={i}>{v}</span>;
      })}
    </Indent>
  );
}

function SwapConfirmModal({
  outImgSrc,
  outImgAlt,
  outValue,
  inImgSrc,
  inImgAlt,
  inValue,
  taxRate,
}) {
  const classNames = [styles['container']];
  if (Number(taxRate) <= 0) {
    classNames.push(styles['tax-free']);
  }
  return (
    <ConfirmModal className={classNames.join(' ')}>
      <span className={styles['title']}>Swap Confirmation</span>
      <Panel className={styles['panel']}>
        <span className={styles['title']}>You are swapping</span>
        <div className={styles['resource-indents']}>
          <ResourceIndent
            imgSrc={outImgSrc}
            imgAlt={outImgAlt}
            value={outValue}
          />
          <ResourceIndent
            imgSrc={inImgSrc}
            imgAlt={inImgAlt}
            value={inValue}
          />
          <SwapArrow />
          <SwapArrow />
        </div>
        <TaxIndent
          proportion={taxRate}
          value={(inValue * (1 - taxRate)).toLocaleString()}
        />
      </Panel>
    </ConfirmModal>
  );
}

export default NiceModal.create(SwapConfirmModal);
