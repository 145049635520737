import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLogout } from 'hooks/query';
import NiceModal from '@ebay/nice-modal-react';
import styles from './hud.module.scss';

import TopBar from './top-bar';
import Menu from './menu';
import BottomBar from './bottom-bar';

import AuthModal from '../modal/auth';
import ConfirmModal from '../modal/confirm';
import LoadingModal from '../modal/loading';
import ActionResultModal from '../modal/action-result';

function Hud({ className, isAppLoaded }) {
  const [isMenuVisible, setMenuVisible] = useState(false);
  useEffect(() => {
    if (isAppLoaded && process.env.NODE_ENV !== 'development') {
      setTimeout(() => {
        setMenuVisible(true);
      }, 5800);
    }
  }, [isAppLoaded]);

  const queryClient = useQueryClient();
  const unauther = useLogout();
  const handleClickDisconnect = () => {
    NiceModal.show(ConfirmModal, {
      children: 'Are you sure you want to disconnect?'
    }).then(() => {
      NiceModal.show(LoadingModal);
      unauther.mutate(null, {
        onSuccess: () => {
          queryClient.clear();
          NiceModal.show(ActionResultModal);
        },
        onError: e => alert(e.response.data.message),
        onSettled: () => NiceModal.hide(LoadingModal),
      });
    });
  };

  const classNames = [styles['container']];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')}>
      <TopBar
        className={styles['top-bar']}
        onClickConnect={() => NiceModal.show(AuthModal)}
        onClickChangeWallet={() => NiceModal.show(AuthModal)}
        onClickDisconnect={handleClickDisconnect}
      />
      <Menu
        className={styles['menu']}
        isVisible={isMenuVisible}
        onClickPlay={() => setMenuVisible(false)}
      />
      <BottomBar className={styles['bottom-bar']} />
    </div>
  )
}

export default Hud;
