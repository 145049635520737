import { useRef } from 'react';
import styles from './quantity-box.module.scss';
import Indent from 'pages/game/modal/panel/indent';

function QuantityBox({
  className,
  imgSrc,
  imgAlt,
  value,
  isInput,
  isCondensed,
  onChange,
  onClickHalf,
  onClickMax,
}) {
  const keyMapRef = useRef({});
  const handleKeyChange = e => {
    keyMapRef.current[e.key] = e.type == 'keydown';
  };
  const allowKey = value => {
    if (['Backspace', 'ArrowLeft', 'ArrowRight'].includes(value)) {
      return true;
    }
    if (keyMapRef.current['a'] && keyMapRef.current['Meta']) {
      return true;
    }
    return /[0-9\.]/.test(value)
  };

  const classNames = [styles['container']];
  if (isCondensed) {
    classNames.push(styles['condensed']);
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <Indent
        className={styles['img-container']}
        isBlack={isCondensed}
      >
        <img src={imgSrc} alt={imgAlt} />
      </Indent>
      <Indent
        className={styles['input-container']}
        isBlack={isCondensed}
      >
        <input
          className="no-spin-btn"
          type="text"
          value={!isInput && isNaN(value) ? '' : (isInput ? value : value.toLocaleString())}
          readOnly={!isInput}
          onChange={e => onChange ? onChange(e.target.value) : null}
          onKeyDown={e => {
            handleKeyChange(e);
            if (!allowKey(e.key)) {
              e.preventDefault();
            }
          }}
          onKeyUp={handleKeyChange}
        />
        {isInput && <div className={styles['input-buttons']}>
          <button onClick={onClickHalf}>50%</button>
          <button onClick={onClickMax}>Max</button>
        </div>}
      </Indent>
    </div>
  );
}

export default QuantityBox;
