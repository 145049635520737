import _uniqueId from 'lodash/uniqueId';
import { useState } from 'react';

import styles from './quantity-panel.module.scss';

function Field(props) {
  const [id] = useState(_uniqueId('tf-'));
  return (
    <div className={styles['field']}>
      <label htmlFor={id}>{props.label}</label>
      <div className={styles['input-container']}>
        <div className={[styles['inc-buttons'], props.isInput ? '' : 'invisible'].join(' ')}>
          <button>▲</button>
          <button>▼</button>
        </div>
        <input className="no-spin-btn" id={id} type="number" readOnly={!props.isInput} />
        {props.isInput && <button className={styles['max-button']}>Max</button>}
      </div>
    </div>
  );
}

function QuantityPanel() {
  return (
    <div className={styles['container']}>
      <Field label="Qty" isInput={true} />
      <Field label="Price per item" />
      <Field label="Total cost" />
    </div>
  );
}

export default QuantityPanel;
