import styles from './option.module.scss';

function Option({ imgSrc, imgAlt, text, onClick }) {
  return (
    <button className={styles['container']} onClick={onClick}>
      <div className={styles['inner']}>
        <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={imgAlt} />
        </div>
        <span>{text}</span>
      </div>
    </button>
  )
}

export default Option;
