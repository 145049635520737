import styles from './indent.module.scss';

function Indent({ className, imgSrc, imgAlt, text }) {
  const classNames = [styles['container'], 'border-3d'];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <div className={`${styles['inner']} border-3d`}>
        <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={imgAlt} />
        </div>
        <span>{text}</span>
      </div>
    </div>
  )
}

export default Indent;
