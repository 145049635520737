import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useRegister, useLogin } from 'hooks/query';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useAuthContext } from 'auth-context';
import styles from './auth-modal.module.scss';

import Modal from 'pages/game/modal';
import Board from 'pages/game/modal/board';

import ActionResultModal from 'pages/game/modal/action-result';

function WalletIdInput({ isDisabled }) {
  return (
    <input
      type="text"
      name="wallet_id"
      placeholder="Wallet ID"
      disabled={isDisabled}
      required
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  );
}

function PasswordInput({ isDisabled }) {
  return (
    <input
      type="password"
      name="password"
      placeholder="Password"
      disabled={isDisabled}
      required
      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    />
  );
}

function Register({ onClickLogin, onSuccess, onError }) {
  const authorizer = useRegister();
  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    authorizer.mutate({
      name: 'User',
      wallet_id: formData.get('wallet_id'),
      password: formData.get('password'),
    }, { onSuccess, onError });
  };
  const isDisabled = authorizer.isLoading;
  return (
    <form
      className={styles['form']}
      onSubmit={handleSubmit}
    >
      <span className={styles['heading']}>Register</span>
      <WalletIdInput isDisabled={isDisabled} />
      <PasswordInput isDisabled={isDisabled} />
      <div className={styles['footer']}>
        <button
          type="button"
          tabIndex="-1"
          onClick={onClickLogin}
          disabled={isDisabled}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        >Already registered?</button>
        <input
          type="submit"
          value="Submit"
          disabled={isDisabled}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        />
      </div>
    </form>
  );
}

function Login({ onClickRegister, onSuccess, onError }) {
  const authorizer = useLogin();
  const handleSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    authorizer.mutate({
      wallet_id: formData.get('wallet_id'),
      password: formData.get('password'),
    }, { onSuccess, onError });
  };
  const isDisabled = authorizer.isLoading;
  return (
    <form
      className={styles['form']}
      onSubmit={handleSubmit}
    >
      <span className={styles['heading']}>Login</span>
      <WalletIdInput isDisabled={isDisabled} />
      <PasswordInput isDisabled={isDisabled} />
      <div className={styles['footer']}>
        <button
          type="button"
          tabIndex="-1"
          onClick={onClickRegister}
          disabled={isDisabled}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        >Not registered?</button>
        <input
          type="submit"
          value="Submit"
          disabled={isDisabled}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        />
      </div>
    </form>
  );
}

function AuthModal() {
  const queryClient = useQueryClient();
  const modal = useModal();
  const [hasAccount, setHasAccount] = useState(true);
  const { setIsAuthed } = useAuthContext();
  const handleSuccess = (data) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    queryClient.invalidateQueries(['user']);
    setIsAuthed(true);
    NiceModal.show(ActionResultModal).then(modal.hide);
  };
  const handleError = (error) => {
    alert(error.response.data.message);
  };
  return (
    <Modal title="Connect wallet">
      <Board className={styles['board']}>
        {hasAccount ?
          <Login
            onClickRegister={() => setHasAccount(false)}
            onSuccess={handleSuccess}
            onError={handleError}
          /> :
          <Register
            onClickLogin={() => setHasAccount(true)}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        }
      </Board>
    </Modal>
  );
}

export default NiceModal.create(AuthModal);
