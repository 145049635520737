import Matter from 'matter-js';
import { lerp } from 'utils';

const collisionFlag = Object.freeze({
  default: 1 << 0,
  mouse: 1 << 1,
});

class Physics {
  get timeScale() {
    return 1.65;
  }

  get chainSeparation() {
    return 0.258;
  }

  constructor(containerElement, hasMouseInteraction) {
    this.containerElement = containerElement;
    
    this.engine = Matter.Engine.create();
    this.engine.timing.timeScale = this.timeScale;

    this.runner = Matter.Runner.create();
    this.group = Matter.Body.nextGroup(true);

    this.chainedBoards = [];

    if (hasMouseInteraction) {
      this.addMouse();
    }
    
    Matter.Runner.run(this.runner, this.engine);
  }

  addChainedBoard(elements) {
    const firstBoardElement = elements.boards[0];
    const prevChainedBoard = this.chainedBoards.length ?
      this.chainedBoards[this.chainedBoards.length - 1] : null;

    // Freeze previous chained board
    if (prevChainedBoard) {
      const setBodyStatic = body => body.isStatic = true;
      prevChainedBoard.leftChainLinks.forEach(setBodyStatic);
      prevChainedBoard.rightChainLinks.forEach(setBodyStatic);
      setBodyStatic(prevChainedBoard.board);
    }

    const [leftHookAnchor, rightHookAnchor] = firstBoardElement.querySelectorAll('.hook-anchor');
    if (!leftHookAnchor || !rightHookAnchor) {
      console.error('[Physics] No hook anchors found on board element.');
    }

    const boardHookOffset = {
      left: this.getPos(
        leftHookAnchor.offsetLeft - 0.5 * firstBoardElement.offsetWidth,
        leftHookAnchor.offsetTop - 0.5 * firstBoardElement.offsetHeight),
      right: this.getPos(
        rightHookAnchor.offsetLeft - 0.5 * firstBoardElement.offsetWidth,
        rightHookAnchor.offsetTop - 0.5 * firstBoardElement.offsetHeight),
    };

    const prevBoardBody = prevChainedBoard ? prevChainedBoard.board : null;
    const leftChainPoint = this.getPos(0.5 * this.containerElement.offsetWidth + boardHookOffset.left.x, 0);
    const rightChainPoint = this.getPos(0.5 * this.containerElement.offsetWidth + boardHookOffset.right.x, 0);

    if (prevChainedBoard) {
      const prevFirstBoardElement = prevChainedBoard.elements.boards[0];
      const [leftHangAnchor, rightHangAnchor] = prevFirstBoardElement.querySelectorAll('.hang-anchor');

      // Ignore X position of hang offset, use current board hook offset instead, so chains are straight.
      leftChainPoint.x = boardHookOffset.left.x;
      rightChainPoint.x = boardHookOffset.right.x;

      leftChainPoint.y = leftHangAnchor.offsetTop - 0.5 * prevFirstBoardElement.offsetHeight;
      rightChainPoint.y = rightHangAnchor.offsetTop - 0.5 * prevFirstBoardElement.offsetHeight;
    }

    const leftChain = this.addChain(
      prevBoardBody,
      leftChainPoint,
      this.chainSeparation,
      elements.leftChainLinks);

    const rightChain = this.addChain(
      prevBoardBody,
      rightChainPoint,
      this.chainSeparation,
      elements.rightChainLinks.reverse());

    const board = this.addBoard(
      boardHookOffset,
      leftChain.bodies[0],
      this.getPos(0, -this.chainSeparation * elements.leftChainLinks[0].offsetHeight),
      rightChain.bodies[0],
      this.getPos(0, -this.chainSeparation * elements.rightChainLinks[0].offsetHeight),
      elements.boards);

    // Keep track of active bodies
    this.chainedBoards.push({
      elements: elements,
      leftChainLinks: leftChain.bodies,
      rightChainLinks: rightChain.bodies,
      board: board,
    });
  }

  addChain(body, point, separation, elements) {
    // Adjust stack position so the chain can start upside-down.
    const avgElementHeight = elements.reduce((acc, el) => acc + el.offsetHeight, 0) / elements.length;
    const stackHeight = lerp(1, elements.length, separation / 0.5) * avgElementHeight;
    const stackPos = this.getPos(point.x, point.y - stackHeight);

    if (body) {
      stackPos.x += body.position.x;
      stackPos.y += body.position.y;
    }

    let i = 0;
    const stack = Matter.Composites.stack(
      stackPos.x, stackPos.y,
      1, elements.length,
      0, -2 * (0.5 - separation) * elements[0].offsetHeight,
      (x, y) => {
        const element = elements[i++];
        return Matter.Bodies.rectangle(
          x, y - 2 * (0.5 - separation) * element.offsetHeight,
          element.offsetWidth, element.offsetHeight,
          {
            collisionFilter: {
              group: this.group,
              category: collisionFlag.default | collisionFlag.mouse,
            },
          });
      });

    Matter.Composites.chain(
      stack,
      0, separation,
      0, -separation,
      {
        stiffness: 1,
        length: 0
      });

    const lastIndex = stack.bodies.length - 1;
    Matter.Composite.add(stack, Matter.Constraint.create({
      bodyA: body,
      pointA: point,
      bodyB: stack.bodies[lastIndex],
      pointB: this.getPos(0,
        separation * elements[lastIndex].offsetHeight),
      stiffness: 1,
      length: 0,
    }));

    Matter.Composite.add(this.engine.world, stack);
    Matter.Events.on(this.runner, 'afterTick', () => {
      stack.bodies.forEach((body, i) => {
        this.elMatchBody(elements[i], body);
      });
    });

    return stack;
  }

  addBoard(
    hookOffset,
    leftHookBody,
    leftHookPoint,
    rightHookBody,
    rightHookPoint,
    elements) {
    // Align position with left hook
    const position = this.getPos(
      leftHookBody.position.x + leftHookPoint.x - hookOffset.left.x,
      leftHookBody.position.y + leftHookPoint.y - hookOffset.left.y);

    const body = Matter.Bodies.rectangle(
      position.x, position.y,
      elements[0].offsetWidth, elements[0].offsetHeight,
      {
        density: 0.0001,
        collisionFilter: {
          group: this.group,
          category: collisionFlag.default | collisionFlag.mouse,
        },
      }
    );

    const addHookConstraint = isRight => {
      Matter.Composite.add(this.engine.world, Matter.Constraint.create({
        bodyA: isRight ? rightHookBody : leftHookBody,
        pointA: isRight ? rightHookPoint : leftHookPoint,
        bodyB: body,
        pointB: isRight ? hookOffset.right : hookOffset.left,
        length: 0,
      }));
    };

    Matter.Composite.add(this.engine.world, body);
    addHookConstraint(false);
    addHookConstraint(true);

    Matter.Events.on(this.runner, 'afterTick', () => {
      elements.forEach(el => {
        this.elMatchBody(el, body);
      });
    });

    return body;
  }

  getPos(x = 0, y = 0) {
    return { x, y };
  }

  elMatchBody(el, body) {
    el.style.top = `${body.position.y - 0.5 * el.offsetHeight}px`;
    el.style.left = `${body.position.x - 0.5 * el.offsetWidth}px`;
    el.style.transform = `rotate(${body.angle}rad)`;
  }

  addMouse() {
    this.mouse = Matter.Mouse.create(this.containerElement);
    Matter.Composite.add(this.engine.world, 
      Matter.MouseConstraint.create(this.engine, {
        mouse: this.mouse,
        constraint: {
            stiffness: 0.2,
        },
        collisionFilter: {
          mask: collisionFlag.mouse,
        }
      }));
  }

  setMouseScale(value) {
    if (this.mouse) {
      Matter.Mouse.setScale(this.mouse, {
        x: value,
        y: value,
      });
    }
  }

  clear() {
    Matter.Runner.stop(this.runner);
    Matter.World.clear(this.engine.world);
    Matter.Engine.clear(this.engine);
  }
}

export default Physics;
