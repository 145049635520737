import { createContext, useContext, useMemo, useState } from 'react';

const AuthContext = createContext({
  isAuthed: false,
  setIsAuthed: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(false);
  const value = useMemo(() => ({
    isAuthed,
    setIsAuthed
  }), [isAuthed]);
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
