import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useToolsAvailability, useToolsMint, useUser } from 'hooks/query';
import { hasOwnProperty } from 'utils';
import { requireToolImgs } from 'game-utils';
import { resourceNames } from 'constants';
import NiceModal from '@ebay/nice-modal-react';
import styles from './castle-tool-modal.module.scss';

import Modal from 'pages/game/modal';
import Board from 'pages/game/modal/board';
import Panel from 'pages/game/modal/panel';
import PanelBoard from 'pages/game/modal/panel-board';
import Rivet from 'pages/game/modal/rivet';
import Control from './control';
import Table from './table';
import Progress from '../progress';
import CtaButton from 'pages/game/modal/cta-button';

import ConfirmModal from 'pages/game/modal/confirm';
import LoadingModal from 'pages/game/modal/loading';
import ActionResultModal from 'pages/game/modal/action-result';

const toolImgs = requireToolImgs();

function Plaque() {
  return (
    <div className={`${styles['plaque']} border-3d`}>
      {Array(4).fill(null).map((_, i) => {
        return <Rivet key={i} />;
      })}
      <span>Stone Pickaxe</span>
    </div>
  );
}

function CastleToolModal() {
  const [toolType, setToolType] = useState('axe');
  const [toolTier, setToolTier] = useState('stone');

  const toolImg = toolImgs[toolType][toolTier];

  const queryClient = useQueryClient();
  const user = useUser();
  const availability = useToolsAvailability(toolType, toolTier);
  const minter = useToolsMint();

  const price = availability.data?.price_res;
  const available = availability.data?.available;
  const total = availability.data?.total;

  const canUserAffordTool = () => {
    if (!user.data || !price) {
      return false;
    }
    for (let i = 0; i < resourceNames.length; ++i) {
      let name = resourceNames[i];
      if (hasOwnProperty(price, name) && user.data[`res_${name}`] < price[name]) {
        return false;
      }
    }
    if (price.gold && user.data.gold < price.gold) {
      return false;
    }
    return true;
  };

  const handleClickMint = () => {
    NiceModal.show(ConfirmModal, {
      children: 'This action will mint a tool'
    }).then(() => {
      NiceModal.show(LoadingModal);
      minter.mutate({
        type: toolType,
        tier: toolTier,
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries(['user']);
          queryClient.invalidateQueries(['tools-availability', toolType, toolTier]);
          queryClient.invalidateQueries(['tools']);
          NiceModal.show(ActionResultModal);
        },
        onError: (error) => alert(error.response.data.message),
        onSettled: () => NiceModal.hide(LoadingModal),
      });
    });
  };

  return (
    <Modal title="Mint a Tool">
      <div className={styles['board-container']}>
        <PanelBoard
          className={styles['left-board']}
          panelClassName={styles['left-board-panel']}
        >
          <div className={styles['img-indent']}>
            <Panel
              className={styles['img-panel']}
              hasGleam={true}
              hasOutline={false}
            >
              <div className={`${styles['img-container']} shadowed-img-container`}>
                <img className="contained" src={toolImg.src} alt={toolImg.alt} />
              </div>
            </Panel>
          </div>
          <Plaque />
          <Control
            toolType={toolType}
            toolTier={toolTier}
            onChangeToolType={e => setToolType(e.target.value)}
            onChangeToolTier={e => setToolTier(e.target.value)}
          />
        </PanelBoard>
        <Board className={styles['right-board']}>
          <Panel className={styles['right-panel']}>
            <Table price={price} />
            <Progress
              className={styles['progress']}
              value={total ? total - available : null}
              total={total}
            />
            <CtaButton
              className={styles['mint-button']}
              isMint={true}
              isDisabled={!canUserAffordTool() || available <= 0 || minter.isLoading}
              onClick={handleClickMint}
            >Mint me!</CtaButton>
          </Panel>
        </Board>
      </div>
    </Modal>
  );
}

export default NiceModal.create(CastleToolModal);
