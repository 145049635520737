import styles from './toggle.module.scss';

function Toggle({ className, name, imgSrc, isChecked, onChange }) {
  const classNames = [styles['container'], 'border-3d'];
  if (className) {
    classNames.push(className);
  }
  return (
    <label className={classNames.join(' ')}>
      <input type="radio" name="resource" className="hidden" checked={isChecked} onChange={onChange} />
      <div className={`${styles['inner']} border-3d`}>
        <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={name} />
        </div>
        <span>{name}</span>
        <div className={styles['inner-border']}></div>
      </div>
      <div className={styles['highlight']}></div>
    </label>
  );
}

export default Toggle;
