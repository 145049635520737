import { useEffect, useState } from 'react';
import styles from './dropdown.module.scss';

import Toggle from './toggle';
import Menu from './menu';

const Dropdown = ({
  className,
  imgSrc,
  imgAlt,
  text,
  isButton,
  onToggleClick,
  children
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleWindowClick = (e) => {
    if (!e.target.closest('.toggle')) {
      setOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener('click', handleWindowClick, true); 
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <Toggle
        className="toggle"
        imgSrc={imgSrc}
        imgAlt={imgAlt}
        text={text}
        isChecked={isOpen}
        onChange={e => setOpen(e.target.checked)}
        styleAsButton={isButton}
        onClick={onToggleClick}
      />
      {!isButton && <Menu
        className={styles['menu']}
        isVisible={isOpen}
      >
        {children}
      </Menu>}
    </div>
  );
}

export default Dropdown;
