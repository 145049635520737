import styles from './panel.module.scss';

import Rivet from '../rivet';

import cornerTlImg from 'assets/images/game/panel/corner-tl.png';
import cornerTrImg from 'assets/images/game/panel/corner-tr.png';
import cornerBrImg from 'assets/images/game/panel/corner-br.png';
import cornerBlImg from 'assets/images/game/panel/corner-bl.png';

function Gleam({ isBright }) {
  const classNames = [styles['gleam']];
  if (isBright) {
    classNames.push(styles['gleam--bright']);
  }
  return <div className={classNames.join(' ')}></div>;
}

function Corner({ imgSrc }) {
  return <img className={styles['corner']} src={imgSrc} alt="Corner" />;
}

function Corners() {
  return (
    <>
      <Corner imgSrc={cornerTlImg} />
      <Corner imgSrc={cornerTrImg} />
      <Corner imgSrc={cornerBrImg} />
      <Corner imgSrc={cornerBlImg} />
    </>
  );
}

function Rivets() {
  return (
    <div className={styles['rivets']}>
      {Array(4).fill(null).map((_, i) => {
        return <Rivet key={i} />
      })}
    </div>
  );
}

function Panel({
  className,
  hasOutline,
  hasGreyOutline,
  hasCorners,
  hasRivets,
  hasGleam,
  hasBrightGleam,
  hasBorder,
  hasThinBorder,
  children,
  isVisible
}) {
  hasOutline = hasOutline ?? true;
  hasCorners = hasCorners ?? true;
  hasBorder = hasBorder ?? true;
  isVisible = isVisible ?? true;
  const classNames = [styles['container']];
  if (hasOutline || hasGreyOutline) {
    classNames.push(styles['outline']);
  }
  if (hasGreyOutline) {
    classNames.push(styles['grey-outline']);
  }
  if (hasBorder || hasThinBorder) {
    classNames.push(styles['border']);
  }
  if (hasThinBorder) {
    classNames.push(styles['thin-border']);
  }
  if (isVisible) {
    classNames.push(styles['visible']);
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      {(hasGleam || hasBrightGleam) && <Gleam isBright={hasBrightGleam} />}
      {hasCorners && <Corners />}
      {hasRivets && <Rivets />}
      {children}
    </div>
  );
}

export default Panel;
