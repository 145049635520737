import styles from './level-bar.module.scss';

function Indent({ children }) {
  return (
    <div className={`${styles['indent']} border-3d`}>
      <div className={`${styles['indent-inner']} border-3d`}>
        {children}
      </div>
    </div>
  );
}

function Notch({ isVisible }) {
  const classNames = ['border-3d'];
  if (!isVisible) {
    classNames.push('invisible');
  }
  return (
    <div className={classNames.join(' ')}></div>
  );
}

function LevelBar({
  level,
  currentExp,
  targetExp,
  isLevelUpDisabled,
  onClickLevelUp
}) {
  const currentExpClamped = Math.min(currentExp, targetExp);
  const progress = targetExp > 0 ? currentExpClamped / targetExp : 0;
  const numNotches = 22;
  const numVisibleNotches = Math.round(progress * numNotches);
  return (
    <div className={styles['container']}>
      <div className={`${styles['plate']} border-3d`}>
        <Indent>
          <span>Lvl: {level}</span>
        </Indent>
        <Indent>
          <div className={styles['notches']}>
            {Array(numNotches).fill(null).map((e, i) => {
              return <Notch key={i} isVisible={i < numVisibleNotches} />;
            })}
          </div>
          <span>{currentExpClamped}/{targetExp}</span>
        </Indent>
      </div>
      <button
        className={`${styles['button']} border-3d`}
        disabled={isLevelUpDisabled}
        onClick={onClickLevelUp}
      >
        <span>Lvl</span>
        <span>Up</span>
      </button>
    </div>
  );
}

export default LevelBar;
