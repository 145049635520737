import { useModal } from '@ebay/nice-modal-react';
import { useRef, useEffect } from 'react';
import { usePrevious } from 'hooks/util';
import { modalScale } from 'constants';
import styles from './blocker.module.scss';
import Scaled from 'pages/game/scaled';

const outDuration = 100; // ms

function Blocker({
  isNonBlocking,
  keepMounted,
  onClick,
  onUnmount,
  children
}) {
  // Pass visibility to style
  const modal = useModal();
  const classNames = [styles['container']];
  if (modal.visible) {
    classNames.push(styles['container--visible']);
  }
  if (isNonBlocking) {
    classNames.push(styles['container--non-blocking']);
  }

  // Register clicks on the blocker and not on its children
  const ref = useRef(null);
  const handleClick = e => {
    if (e.target === ref.current && onClick) {
      onClick();
    }
  };

  // Remove the modal from the DOM after the out transition when it is hidden.
  const timeoutRef = useRef(null);
  const prevVisible = usePrevious(modal.visible);
  useEffect(() => {
    if (modal.visible) {
      clearTimeout(timeoutRef.current);
    } else if (prevVisible && !keepMounted) {
      timeoutRef.current = setTimeout(() => {
        modal.remove();
        if (onUnmount) {
          onUnmount();
        }
      }, outDuration);
    }
  }, [modal.visible]);

  return (
    <Scaled
      ref={ref}
      className={classNames.join(' ')}
      modifier={modalScale}
      onClick={handleClick}
    >
      {children}
    </Scaled>
  );
}

export default Blocker;
