import NiceModal from '@ebay/nice-modal-react';
import styles from './loading-modal.module.scss';

import Blocker from '../blocker';
import PanelBoard from '../panel-board';

import mugsImg from 'assets/images/game/loading-mugs.png';

function LoadingModal() {
  return (
    <Blocker>
      <PanelBoard
        className={styles['container']}
        panelClassName={styles['panel']}
        panelHasBorder={false}
        hasDropShadow={true}
      >
        <span className={styles['title']}>Loading...</span>
        <div className={styles['img-container']}>
          <img
            className="contained"
            src={mugsImg}
            alt="Clinking mugs of beer"
          />
        </div>
      </PanelBoard>
    </Blocker>
  );
}

export default NiceModal.create(LoadingModal);
