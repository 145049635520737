import { useNftsAvailability, useToolsAvailability } from 'hooks/query';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styles from './castle-select-modal.module.scss';

import Modal from 'pages/game/modal';
import Board from 'pages/game/modal/board';
import Option from './option';

import CastleNftModal from 'pages/game/modal/castle/nft';
import CastleToolModal from 'pages/game/modal/castle/tool';

import toolLargeImg from 'assets/images/game/castle/tool-large.png';
import characterLargeImg from 'assets/images/game/castle/character-large.png';

function CastleSelectModal() {
  const nftAvailability = useNftsAvailability();
  const toolsAvailability = useToolsAvailability();
  const modal = useModal();
  const goToModal = (nextModal) => {
    modal.hide();
    NiceModal.show(nextModal);
  };
  return (
    <Modal title="Mint Selection">
      <Board className={styles['board']}>
        <Option
          className={styles['option']}
          title="NFTs"
          titleBackgroundColor="red"
          imgSrc={characterLargeImg}
          description="Mint one of the cool nfts to gather resources and fight tons of mobs"
          available={nftAvailability.data ? nftAvailability.data.available : 0}
          total={nftAvailability.data ? nftAvailability.data.total : 0}
          onClick={() => goToModal(CastleNftModal)}
        />
        <Option
          className={styles['option']}
          title="Tools"
          titleBackgroundColor="blue"
          imgSrc={toolLargeImg}
          description="Mint one of the cool tools to gather resources and fight tons of mobs"
          available={toolsAvailability.data ? toolsAvailability.data.available : 0}
          total={toolsAvailability.data ? toolsAvailability.data.total : 0}
          onClick={() => goToModal(CastleToolModal)}
        />
      </Board>
    </Modal>
  );
}

export default NiceModal.create(CastleSelectModal);
