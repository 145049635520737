import styles from './option.module.scss';

import Panel from 'pages/game/modal/panel';
import PanelBoard from 'pages/game/modal/panel-board';
import CtaButton from 'pages/game/modal/cta-button/CtaButton';

function Option({ className, title, titleBackgroundColor, imgSrc, description, available, total, onClick }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <PanelBoard
      className={classNames.join(' ')}
      panelClassName={styles['panel']}
      title={title}
      titleBackgroundColor={titleBackgroundColor}
    >
      <div className={`${styles['img-container']} shadowed-img-container`}>
        <img className="contained" src={imgSrc} alt={title} />
      </div>
      <div className={styles['info-container']}>
        <Panel
          className={styles['info-panel']}
          hasOutline={false}
          hasCorners={false}
          hasRivets={true}
          hasThinBorder={true}
        >
          <p>{description}</p>
          <span>Available {available}/{total}</span>
          <CtaButton
            className={styles['mint-button']}
            isMint={true}
            onClick={onClick}
          >Mint</CtaButton>
        </Panel>
      </div>
    </PanelBoard>
  );
}

export default Option;
