import NiceModal from '@ebay/nice-modal-react';
import styles from './game.module.scss';

import Scaled from './scaled';
import Map from './map';
import Hud from './hud';

import MarketSelectModal from './modal/market/select';
import CastleSelectModal from './modal/castle/select';
import PoolModal from './modal/pool';

function Game({ isAppLoaded }) {
  return (
    <Scaled className={styles['container']} >
      <Map
        className={styles['map']}
        onClickObject={id => {
          switch (id) {
            case 'mine': return NiceModal.show(PoolModal, { pool: 'mining' });
            case 'barracks': return NiceModal.show(PoolModal, { pool: 'hunting' });
            case 'log-house': return NiceModal.show(PoolModal, { pool: 'woodcutting' });
            case 'farm': return NiceModal.show(PoolModal, { pool: 'farming' });
            case 'dock': return NiceModal.show(PoolModal, { pool: 'fishing' });
            case 'river': return NiceModal.show(PoolModal, { pool: 'pvp' });
            case 'castle': return NiceModal.show(CastleSelectModal);
            case 'market': return NiceModal.show(MarketSelectModal);
          }
        }}
      />
      <Hud isAppLoaded={isAppLoaded} />
    </Scaled>
  );
}

export default Game;
