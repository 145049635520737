import styles from './close-button.module.scss';

import rivetImg from 'assets/images/game/close-button/rivet.png';
import crossImg from 'assets/images/game/close-button/cross.png';

function CloseButton({ className, ...props }) {
  const classNames = [styles['container'], 'border-3d', 'no-click'];
  if (className) {
    classNames.push(className);
  }
  return (
    <button className={classNames.join(' ')} {...props} >
      <div className={`${styles['inner1']} border-3d no-click`}>
        <div className={`${styles['inner2']} border-3d`}>
          {Array(4).fill(null).map((_, i) => {
            return (
              <img
                key={i}
                className={styles['rivet']}
                src={rivetImg}
                alt="Rivet"
              />
            );
          })}
          <div className={styles['cross-container']}>
            <img className="contained" src={crossImg} alt="Cross" />
          </div>
        </div>
      </div>
    </button>
  );
}

export default CloseButton;
