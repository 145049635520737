import styles from './play-button.module.scss';

import FireRing from './fire-ring';

function PlayButton({ className, animateFire, onClick }) {
  const classNames = [styles['container']];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')}>
      <button
        className={styles['button']}
        onClick={onClick}
      >
        <FireRing
          id="fire-ring"
          className={styles['fire-ring']}
          animate={animateFire}
        />
        <div className={styles['inner-1']}>
          <div className={styles['inner-2']}>
            <span>Play</span>
          </div>
        </div>
      </button>
    </div>
  );
}

export default PlayButton;
