import styles from './plate.module.scss';

function Plate({ className, hasOutline, hasGleam, hasBrightGleam, hasDropShadow, children }) {
  const classNames = [styles['container'], 'border-3d'];
  if (hasOutline) {
    classNames.push(styles['outline']);
  }
  if (hasGleam || hasBrightGleam) {
    classNames.push(styles['gleam']);
  }
  if (hasBrightGleam) {
    classNames.push(styles['bright-gleam']);
  }
  if (hasDropShadow) {
    classNames.push(styles['drop-shadow']);
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
}

export default Plate;
