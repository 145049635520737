import styles from './rivet.module.scss';
import rivetImg from 'assets/images/game/rivet.png';

function Rivet() {
  return (
    <img
      className={styles['container']}
      src={rivetImg}
      alt="Rivet"
    />
  );
}

export default Rivet;
