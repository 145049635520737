import styles from './button.module.scss';

function Button({ className, imgSrc, imgAlt, text, isUppercase, ...props }) {
  isUppercase = isUppercase ?? true;
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  if (isUppercase) {
    classNames.push(styles['uppercase']);
  }
  return (
    <button className={classNames.join(' ')} {...props}>
      <div className={styles['inner']}>
        {imgSrc && <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={imgAlt} />
        </div>}
        <span>{text}</span>
      </div>
    </button>
  )
}

export default Button;
