import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styles from './market-select-modal.module.scss';

import Modal from 'pages/game/modal';
import Board from 'pages/game/modal/board';
import Option from './option';

import resourcesImg from 'assets/images/game/market/select/resources.png';
import toolsImg from 'assets/images/game/market/select/tools.png';

import MarketResourcesModal from 'pages/game/modal/market/resources';
import MarketToolsModal from 'pages/game/modal/market/tools';

function MarketSelectModal() {
  const modal = useModal();
  const goToModal = (nextModal) => {
    modal.hide();
    NiceModal.show(nextModal);
  };
  return (
    <Modal title="Trade Selection">
      <Board className={styles['board']}>
        <Option
          className={styles['option']}
          title="Resources"
          titleBackgroundColor="red"
          imgSrc={resourcesImg}
          onClick={() => goToModal(MarketResourcesModal)}
        >
          <p>Trade your hard earned resources for precious gold.</p>
          <p>But remember you owe 20% to the King each trade.</p>
        </Option>
        <Option
          className={styles['option']}
          title="Tools"
          titleBackgroundColor="blue"
          imgSrc={toolsImg}
          onClick={() => goToModal(MarketToolsModal)}
        >
          <p>Got plenty of tools you don't need anymore? Sell em to others!</p>
          <p>But remember you owe 20% to the King each trade.</p>
        </Option>
      </Board>
    </Modal>
  );
}

export default NiceModal.create(MarketSelectModal);
