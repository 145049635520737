import styles from './item.module.scss';

import epicSwordImg from 'assets/images/game/market/sword-epic.png';
import buyImg from 'assets/images/game/market/item-buy.png';
import sellImg from 'assets/images/game/market/item-sell.png';

function Item({
  className,
  isBuy,
  isSell,
  isButton,
  isChecked,
  onCheckboxChange,
  onClick
}) {
  const isOrder = isBuy || isSell;
  const classNames = [styles['container']];
  if (isOrder) {
    classNames.push(styles['order'])
  }
  if (className) {
    classNames.push(className)
  }
  return (
    <label className={classNames.join(' ')}>
      <input
        type={isButton ? 'button' : 'radio'}
        name="item"
        className="hidden"
        checked={isChecked}
        onChange={onCheckboxChange}
        onClick={onClick}
      />
      <div className={styles['background']}></div>
      <div className={styles['highlight']}></div>
      <div className={styles['img-container']}>
        <img src={epicSwordImg} alt="Epic sword"/>
      </div>
      <div className={styles['info-container']}>
        <span>Knights sword</span>
        <span>Epic</span>
        <span>15,000 gold</span>
      </div>
      {isOrder && <img
        className={styles['order-tag']}
        src={isBuy ? buyImg : sellImg}
        alt={isBuy ? "Buy" : "Sell"}
      />}
    </label>
  );
}

export default Item;
