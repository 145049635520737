import styles from './option.module.scss';

import PanelBoard from 'pages/game/modal/panel-board';
import Rivet from 'pages/game/modal/rivet';
import CtaButton from 'pages/game/modal/cta-button';

function Option(props) {
  const classNames = [styles['container']];
  if (props.className) {
    classNames.push(props.className);
  }
  return (
    <PanelBoard
      className={classNames.join(' ')}
      title={props.title}
      titleBackgroundColor={props.titleBackgroundColor}
      panelClassName={styles['panel']}
    >
      <div className={styles['indent']}>
        <div className={styles['img-container']}>
          <img src={props.imgSrc} alt={props.title} />
        </div>
        <div className={styles['rivets']}>
          {Array(4).fill(null).map((_, i) => {
            return <Rivet key={i} />;
          })}
        </div>
      </div>
      <div className={styles['content']}>
        {props.children}
      </div>
      <CtaButton
        className={styles['button']}
        isTertiary={true}
        onClick={props.onClick}
      >Trade</CtaButton>
    </PanelBoard>
  );
}

export default Option;
