import { useState } from 'react';
import { poolToToolType, requireToolImgs } from 'game-utils';
import styles from './tools-panel.module.scss';

import Panel from 'pages/game/modal/panel';
import TitlePlate from '../title-plate';
import InfoBox from './info-box';
import ToolBox from './tool-box';
import BoostButton from '../boost-button';
import CtaButton from 'pages/game/modal/cta-button';

const toolImgs = requireToolImgs();

function ToolsPanel({
  className,
  pool,
  baseGatherSpeed,
  multiplier,
  toolType,
  toolTier,
  isToolDisabled,
  canClearTool,
  onClickTool,
  onClickClearTool,
  isStakeDisabled,
  onClickStake,
}) {
  const [isToolBoxVisible, setToolBoxVisible] = useState(false);
  const handleClickTool = (id) => {
    setToolBoxVisible(false);
    if (onClickTool) {
      onClickTool(id);
    }
  };
  const handleClickClearTool = () => {
    setToolBoxVisible(false);
    if (onClickClearTool) {
      onClickClearTool();
    }
  };
  const toolImg = toolType && toolTier ? toolImgs[toolType][toolTier] : null;
  const classNames=[styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <Panel className={classNames.join(' ')}>
      <TitlePlate title="Selection Info" />
      <div className={styles['box-container']}>
        <InfoBox
          className={styles['box']}
          isVisible={!isToolBoxVisible}
          toolType={poolToToolType(pool)}
          baseGatherSpeed={baseGatherSpeed}
          multiplier={multiplier}
        />
        <ToolBox
          className={styles['box']}
          pool={pool}
          isVisible={isToolBoxVisible}
          canClear={canClearTool}
          onClickTool={handleClickTool}
          onClickClear={handleClickClearTool}
        />
      </div>
      <div className={styles['boost-buttons']}>
        <BoostButton
          imgSrc={toolImg?.src}
          imgAlt={toolImg?.alt}
          label="Tool"
          isDisabled={isToolDisabled}
          onClick={() => setToolBoxVisible(!isToolBoxVisible)}
        />
        <BoostButton isBlocked={true} />
        <BoostButton isBlocked={true} />
      </div>
      <CtaButton
        className={styles['stake-button']}
        isDisabled={isStakeDisabled}
        onClick={onClickStake}
      >Stake</CtaButton>
    </Panel>
  );
}

export default ToolsPanel;
