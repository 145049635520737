import React from 'react';
import internalStyles from './board.module.scss';

//import SwordButton from './sword-button';
import PlayButton from './play-button';

const Board = React.forwardRef(({
  className,
  styles,
  hideHooks,
  onlyHooks,
  children,
  includePlayButton,
  animatePlayButtonFire,
  onClickPlay,
}, ref) => {
  const classNames = [
    internalStyles['container'],
    styles['container']
  ];
  if (hideHooks) classNames.push(internalStyles['hide-hooks']);
  if (onlyHooks) classNames.push(internalStyles['only-hooks']);
  if (className) classNames.push(className);
  return (
    <div ref={ref} className={classNames.join(' ')}>
      <div className={internalStyles['hooks'] + ' ' + (styles['hooks'] ?? '')}>
        <div></div>
        <div></div>
      </div>
      <div className={internalStyles['hook-anchors'] + ' ' + (styles['hook-anchors'] ?? '')}>
        <div className="hook-anchor"></div>
        <div className="hook-anchor"></div>
      </div>
      {children}
      {includePlayButton && <PlayButton
        className={internalStyles['play-button']}
        animateFire={animatePlayButtonFire}
        onClick={onClickPlay}
      />}
      <div className={internalStyles['hang-anchors'] + ' ' + (styles['hang-anchors'] ?? '')}>
        <div className="hang-anchor"></div>
        <div className="hang-anchor"></div>
      </div>
    </div>
  );
});

export default Board;
