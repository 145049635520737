import styles from './orders-panel.module.scss';

import Panel from 'pages/game/modal/panel';
import Item from '../item';
import plusImg from 'assets/images/game/market/plus.png';

function EmptySlot({ onClick }) {
  return (
    <button
      className={`${styles['empty-button']} border-3d`}
      onClick={onClick}
    >
      <img src={plusImg} alt="Plus" />
      <span>Place order</span>
    </button>
  );
}

function OrdersPanel({ existing, numEmptySlots, onClickEmptySlot }) {
  return (
    <Panel className={styles['container']}>
      <div className={`${styles['header']} border-3d`}>
        <span>Current orders</span>
      </div>
      <div className={`${styles['list']} scrollbar`}>
        {existing.map((isBuy, i) => {
          return <Item key={i} isButton={true} isBuy={isBuy} isSell={!isBuy} />
        })}
        {Array(numEmptySlots).fill(null).map((v, i) => {
          return <EmptySlot key={i} onClick={onClickEmptySlot} />;
        })}
      </div>
    </Panel>
  );
}

export default OrdersPanel;
