import { toolTypes, toolTiers } from 'constants';
import styles from './control.module.scss';

const toolImgs = {};
toolTypes.forEach(type => {
  toolImgs[type] = require(`assets/images/game/castle/tools/${type}.png`);
});

function ToolButton({
  imgSrc,
  imgAlt,
  value,
  isChecked,
  onChange
}) {
  return (
    <label className={styles['tool-button']}>
      <input
        type="radio"
        className="hidden"
        name="tool"
        value={value}
        checked={isChecked}
        onChange={onChange}
      />
      <div className={`${styles['tool-button-inner']} border-3d`}>
        <div className={styles['tool-button-img-container']}>
          <img src={imgSrc} alt={imgAlt} />
        </div>
      </div>
    </label>
  );
}

function ToolButtons({ checkedValue, onChange }) {
  return (
    <div className={styles['tool-buttons']}>
      {Object.keys(toolImgs).map(type => {
        const img = toolImgs[type];
        return (
          <ToolButton
            key={type}
            imgSrc={img}
            imgAlt={type}
            value={type}
            isChecked={type === checkedValue}
            onChange={onChange}
            />
        );
      })}
    </div>
  );
}

function MaterialButton({
  name,
  value,
  isChecked,
  isDisabled,
  onChange
}) {
  return (
    <label className={styles['material-button']}>
      <input
        type="radio"
        className="hidden"
        name="material"
        value={value}
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
      />
      <div className={`${styles['material-button-inner']} border-3d`}>
        <span>{name}</span>
      </div>
    </label>
  );
}

function TierButtons({ checkedValue, onChange }) {
  return (
    <div className={styles['tier-buttons']}>
      {toolTiers.map(tier => {
        return (
          <MaterialButton
            key={tier}
            name={tier}
            value={tier}
            isChecked={tier === checkedValue}
            onChange={onChange}
          />
        );
      })}
      <MaterialButton
        key={'mythic'}
        name={'mythic'}
        isDisabled={true}
      />
    </div>
  );
}

function Control({ toolType, toolTier, onChangeToolType, onChangeToolTier }) {
  return (
    <div className={styles['container']}>
      <ToolButtons checkedValue={toolType} onChange={onChangeToolType} />
      <TierButtons checkedValue={toolTier} onChange={onChangeToolTier} />
    </div>
  );
}

export default Control;
