import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useNfts, useNftsGather, useNftsStake, useNftsUnstake, useToolsAssign, useToolsUnassign } from 'hooks/query';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { poolToResourceName, requireResourceImgs, sumNftResources } from 'game-utils';
import styles from './pool-modal.module.scss';

import Modal from 'pages/game/modal';
import Board from 'pages/game/modal/board';
import WorkersPanel from './workers-panel';
import ToolsPanel from './tools-panel';
import StatsPanel from './stats-panel';

import WorkerModal from './worker-modal';
import ConfirmModal from '../confirm';
import ResourcesConfirmModal from './resources-confirm';
import LoadingModal from '../loading';
import ActionResultModal from '../action-result';

const resourceImgs = requireResourceImgs();

function PoolModal({ pool }) {
  const resourceName = poolToResourceName(pool);
  const workerModal = useModal(WorkerModal);
  const resourcesConfirmModal = useModal(ResourcesConfirmModal);
  const loadingModal = useModal(LoadingModal);
  const actionResultModal = useModal(ActionResultModal);

  const [tab, setTab] = useState('staked');
  const [checkedId, setCheckedId] = useState(null);

  const checkFirstNft = () => {
    const targetPool = tab === 'staked' ? pool : null;
    if (checkedNft && checkedNft.pool === targetPool) {
      return;
    }
    setCheckedId(nfts.data?.find(d => d.pool === targetPool)?.id);
  };

  const queryClient = useQueryClient();
  const nfts = useNfts({ pool: pool });

  const checkedNft = nfts.data?.find(d => d.id === checkedId);
  const isCheckedNftStaked = checkedNft && !!checkedNft.pool;
  const checkedNftTool = checkedNft?.tools[0] ?? null;
  const stakedNfts = nfts.data ? nfts.data.filter(d => d.pool === pool) : [];

  useEffect(() => {
    checkFirstNft();
  }, [tab, nfts.data]);

  const showResourcesConfirmModal = () => {
    return resourcesConfirmModal.show({
      imgSrc: resourceImgs[resourceName] ?? null,
      imgAlt: resourceName,
      amount: resourceName ? checkedNft[`res_${resourceName}`] : sumNftResources(checkedNft),
      numNfts: 1,
      totalNfts: stakedNfts.length,
    });
  };

  // Keep resource confirm modal up-to-date.
  useEffect(() => {
    if (checkedNft && resourcesConfirmModal.visible) {
      showResourcesConfirmModal();
    }
  }, [checkedNft]);

  const staker = useNftsStake();
  const gatherer = useNftsGather();
  const unstaker = useNftsUnstake();
  const toolAssigner = useToolsAssign();
  const toolUnassigner = useToolsUnassign();

  const handleClickGather = () => {
    showResourcesConfirmModal().then(() => {
      loadingModal.show();
      gatherer.mutate({
        id: checkedId,
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries(['user']);
          queryClient.invalidateQueries(['nfts']);
          actionResultModal.show();
        },
        onError: e => alert(e.response.data.message),
        onSettled: () => loadingModal.hide(),
      });
    });
  };

  const handleClickUnstake = () => {
    NiceModal.show(ConfirmModal, {
      children: 'This action will unstake your NFTs'
    }).then(() => {
      loadingModal.show();
      unstaker.mutate({
        id: checkedId,
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries(['user']);
          queryClient.invalidateQueries(['nfts']);
          actionResultModal.show();
        },
        onError: e => alert(e.response.data.message),
        onSettled: () => loadingModal.hide(),
      });
    });
  };

  const handleClickTool = (id) => {
    loadingModal.show();
    toolAssigner.mutate({
      id: id,
      nft_id: checkedId,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['nfts']);
        queryClient.invalidateQueries(['tools']);
      },
      onError: e => alert(e.response.data.message),
      onSettled: () => loadingModal.hide(),
    });
  };

  const handleClickClearTool = () => {
    loadingModal.show();
    toolUnassigner.mutate({
      id: checkedNftTool.id,
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries(['nfts']);
        queryClient.invalidateQueries(['tools']);
      },
      onError: e => alert(e.response.data.message),
      onSettled: () => loadingModal.hide(),
    });
  };

  const handleClickStake = () => {
    NiceModal.show(ConfirmModal, {
      children: 'This action will stake your NFT'
    }).then(() => {
      loadingModal.show();
      staker.mutate({
        id: checkedId,
        pool: pool,
      }, {
        onSuccess: () => {
          queryClient.invalidateQueries(['nfts']);
          // If the NFT is holding a tool that is incompatible with the current pool, 
          // this will be removed on stake, so invalidate tools data now.
          queryClient.invalidateQueries(['tools']);
          actionResultModal.show();
        },
        onError: e => alert(e.response.data.message),
        onSettled: () => loadingModal.hide(),
      });
    });
  };

  const isLoading = (
    nfts.isLoading ||
    staker.isLoading || 
    gatherer.isLoading || 
    unstaker.isLoading || 
    toolAssigner.isLoading ||
    toolUnassigner.isLoading
  );

  return (
    <Modal title={pool} onUnmount={workerModal.remove}>
      <div className={styles['inner']}>
        <Board className={styles['board']}>
          <WorkersPanel
            className={styles['panel']}
            pool={pool}
            tab={tab}
            onChangeTab={e => setTab(e.target.value)}
            datas={nfts.data ?? []}
            checkedId={checkedId}
            onChangeCheckedId={id => setCheckedId(id)}
          />
          {tab === 'available' ?
            <ToolsPanel
              className={styles['panel']}
              pool={pool}
              baseGatherSpeed={checkedNft?.base_gather_speed ?? 0}
              multiplier={checkedNft?.gather_multiplier ?? 1}
              toolType={checkedNftTool?.type}
              toolTier={checkedNftTool?.tier}
              isToolDisabled={!checkedNft || isLoading}
              canClearTool={!!checkedNftTool}
              onClickTool={handleClickTool}
              onClickClearTool={handleClickClearTool}
              isStakeDisabled={!checkedNft || isCheckedNftStaked || isLoading}
              onClickStake={handleClickStake}
            /> : (
            <StatsPanel
              className={styles['panel']}
              availableToGather={nfts.data?.reduce((sum, nft) => sum + sumNftResources(nft), 0) ?? 0}
              totalStaked={stakedNfts.length}
              isGatherDisabled={!isCheckedNftStaked || isLoading}
              isUnstakeDisabled={!isCheckedNftStaked || isLoading}
              onClickGather={handleClickGather}
              onClickUnstake={handleClickUnstake}
            />
          )}
        </Board>
      </div>
    </Modal>
  );
}

export default NiceModal.create(PoolModal);
