import styles from './filter.module.scss';

function Filter({ className, imgSrc, imgAlt }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <label className={classNames.join(' ')}>
      <input type="checkbox" className="hidden" />
      <div className={`${styles['inner']} border-3d`}>
        <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={imgAlt} />
        </div>
      </div>
    </label>
  );
}

export default Filter;
