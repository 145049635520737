import { marketFilters as filters } from 'constants';
import styles from './items-panel.module.scss';

import Panel from 'pages/game/modal/panel';
import Filter from './filter';
import Item from '../item';
import ItemSimple from '../item-simple';

import goldImg from 'assets/images/game/gold.png';

const filterImgs = {};
filters.forEach(filter => {
  filterImgs[filter] = require(`assets/images/game/market/filters/${filter}.png`);
});

function Balance() {
  return (
    <div className={styles['balance']}>
      <label>Available balance</label>
      <div className={styles['balance-display']}>
        <span>15,000.00</span>
        <img src={goldImg} alt="Gold"/>
      </div>
    </div>
  );
}

function Items({ isSimple, selectedIndex, onItemCheckboxChange }) {
  const innerClassNames = [styles['items-inner'], 'scrollbar'];
  if (isSimple) {
    innerClassNames.push(styles['items-inner--simple']);
  }
  return (
    <div className={styles['items']}>
      <div className={innerClassNames.join(' ')}>
        {Array(29).fill(null).map((v, i) => {
          return isSimple ? (
            <ItemSimple
              key={i}
              className={styles['item-simple']}
              isChecked={i === selectedIndex}
              onCheckboxChange={e => onItemCheckboxChange(e, i)}
            />
          ) : (
            <Item
              key={i}
              className={styles['item']}
              isChecked={i === selectedIndex}
              onCheckboxChange={e => onItemCheckboxChange(e, i)}
            />
          )})}
      </div>
    </div>
  );
}

function ItemsPanel({ isSimple, selectedIndex, onItemCheckboxChange }) {
  return (
    <Panel className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['filters']}>
          {filters.map(name => {
            return (
              <Filter
                key={name}
                className={styles['filter']}
                imgSrc={filterImgs[name]}
                imgAlt={name}
              />
            );
          })}
        </div>
        <Balance />
      </div>
      <Items
        isSimple={isSimple}
        selectedIndex={selectedIndex}
        onItemCheckboxChange={onItemCheckboxChange}
      />
    </Panel>
  );
}

export default ItemsPanel;
