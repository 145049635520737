import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styles from './action-result-modal.module.scss';

import Blocker from '../blocker';
import PanelBoard from '../panel-board';
import CtaButton from '../cta-button';

import hammersImg from 'assets/images/game/success-hammers.png';
import swordImg from 'assets/images/game/failed-sword.png';

function ActionResultModal({ isFail }) {
  const modal = useModal();
  const title = isFail ? 'Action failed!' : 'Success!';
  const text = isFail ?
    <>Please check the console for<br/>error codes!</> :
    <>The task has been<br/>completed!</>;
  const imgSrc = isFail ? swordImg : hammersImg;
  const imgAlt = isFail ? 'Broken sword' : 'Sparkling hammers';
  const buttonText = isFail ? 'Try again' : 'Awesome';
  const handleButtonClick = () => {
    modal.resolve();
    modal.hide();
  };
  const classNames = [styles['container']];
  if (isFail) {
    classNames.push(styles['fail']);
  }
  return (
    <Blocker onClick={modal.hide}>
      <PanelBoard
        className={classNames.join(' ')}
        panelClassName={styles['panel']}
        panelHasBorder={false}
        hasDropShadow={true}
      >
        <span className={styles['title']}>{title}</span>
        <p className={styles['text']}>{text}</p>
        <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={imgAlt} />
        </div>
        <CtaButton
          className={styles['button']}
          isSecondary={isFail}
          onClick={handleButtonClick}
        >{buttonText}</CtaButton>
      </PanelBoard>
    </Blocker>
  );
}

export default NiceModal.create(ActionResultModal);
