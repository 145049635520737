import styles from './boost-button.module.scss';
import blockedImg from 'assets/images/game/pool/blocked.png';
import plusImg from 'assets/images/game/pool/plus.png';

function BoostButton({
  className,
  imgSrc,
  imgAlt,
  label,
  isSmall,
  isBlocked,
  isDisabled,
  onClick
}) {
  const classNames = [styles['container']];
  const isEmpty = !imgSrc;
  if (isEmpty) {
    classNames.push(styles['empty']);
    imgSrc = plusImg;
    imgAlt = "Add";
  }
  if (isSmall) {
    classNames.push(styles['small']);
  }
  if (isBlocked) {
    classNames.push(styles['blocked']);
    imgSrc = blockedImg;
    imgAlt = "Blocked";
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <button
        className={`${styles['button']} border-3d`}
        onClick={onClick}
        disabled={isBlocked || isDisabled}
      >
        <div className={`${styles['button-inner']} border-3d`}>
          <div className={styles['img-container']}>
            <img
              className="contained"
              src={imgSrc}
              alt={imgAlt}
            />
          </div>
        </div>
      </button>
      <span className={styles['label']}>{label ?? '?'}</span>
    </div>
  )
}

export default BoostButton;
