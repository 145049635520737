import { useEffect, useRef, useState } from 'react';
import styles from './menu.module.scss';

// Board styles
import crackedBoardStyles from './chained-board/board/cracked-board.module.scss';
import snowBoardStyles from './chained-board/board/snow-board.module.scss';
import woodBoardStyles from './chained-board/board/wood-board.module.scss';
import tentacleBoardStyles from './chained-board/board/tentacle-board.module.scss';
import bloodBoardStyles from './chained-board/board/blood-board.module.scss';
import plainBoardStyles from './chained-board/board/plain-board.module.scss';

import ChainedBoard from './chained-board';
import ChainedBoardContent from './chained-board/content';
import Physics from './physics';

const boardStyles = [
  crackedBoardStyles,
  snowBoardStyles,
  woodBoardStyles,
  tentacleBoardStyles,
  bloodBoardStyles,
  plainBoardStyles,
];

// cb(s) = chained board(s)

const cbAnimMs = 1800;

function Menu({
  className,
  isVisible,
  onClickBlocker,
  onClickPlay,
}) {
  const ref = useRef(null);
  const innerRef = useRef(null);
  const physicsRef = useRef(null);
  const cbsRef = useRef([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]);

  const [numCbsVisible, setNumCbsVisible] = useState(0);
  const numCbsVisibleRef = useRef(0); // use a ref for non-react logic
  const isCbAnimatingRef = useRef(false);
  const cbTimeoutRef = useRef(null);
  const tryShowNextCb = onAnimComplete => {
    if (!isCbAnimatingRef.current &&
      numCbsVisibleRef.current < cbsRef.current.length) {
      if (cbTimeoutRef.current) {
        clearTimeout(cbTimeoutRef.current);
      }
      physicsRef.current.addChainedBoard(
        cbsRef.current[numCbsVisibleRef.current].current);
      setNumCbsVisible(++numCbsVisibleRef.current);

      isCbAnimatingRef.current = true;
      cbTimeoutRef.current = setTimeout(() => {
        isCbAnimatingRef.current = false;
        if (onAnimComplete) {
          onAnimComplete();
        }
      }, cbAnimMs);
    }
  };
  
  const tryShowNextCbIfScrolled = () => {
    if (!numCbsVisibleRef.current) {
      return;
    }
    const cbRef = cbsRef.current[numCbsVisibleRef.current - 1];
    if (cbRef.current) {
      const boardEl = cbRef.current.boards[0];
      const boardBottomY = parseInt(boardEl.style.top) + 0.93 * boardEl.offsetHeight;
      if (ref.current.scrollTop >= boardBottomY - 0.5 * ref.current.offsetHeight) {
        tryShowNextCb(tryShowNextCbIfScrolled);
      }
    }
  };

  useEffect(() => {
    if (physicsRef.current) {
      physicsRef.current.clear();
    }
    setNumCbsVisible(0);
    numCbsVisibleRef.current = 0;
    isCbAnimatingRef.current = false;
    if (cbTimeoutRef.current) {
      clearTimeout(cbTimeoutRef.current);
    }
    if (isVisible) {
      ref.current.scrollTop = 0;
      const resetEl = el => {
        el.style.top = 0;
        el.style.left = 0;
      };
      cbsRef.current.forEach(ref => {
        ref.current.leftChainLinks.forEach(resetEl);
        ref.current.rightChainLinks.forEach(resetEl);
        ref.current.boards.forEach(resetEl);
      });
      physicsRef.current = new Physics(innerRef.current, false);
      tryShowNextCb(tryShowNextCbIfScrolled);
    }
  }, [isVisible]);

  // Keep physics mouse scale up-to-date
  /*const scale = useWindowScale();
  useEffect(() => {
    if (isVisible && physicsRef.current) {
      physicsRef.current.setMouseScale(1 / scale);
    }
  }, [isVisible, scale]);*/

  const handleScroll = () => {
    if (!isCbAnimatingRef.current) {
      tryShowNextCbIfScrolled();
    }
  };

  const handleBlockerClick = e => {
    if (e.target === innerRef.current && onClickBlocker) {
      onClickBlocker();
    }
  };

  useEffect(() => {
    let minHeight = 0;
    const firstCbRef = cbsRef.current[0];
    if (ref.current && firstCbRef.current) {
      const boardHeight = firstCbRef.current.boards[0].offsetHeight;
      const chainHeight = 0.3 * boardHeight; // estimate
      minHeight = (chainHeight + boardHeight) * numCbsVisible;
      if (numCbsVisible === cbsRef.current.length) {
        minHeight += chainHeight;
      } else {
        minHeight += 0.9 * ref.current.offsetHeight;
      }
    }
    innerRef.current.style.minHeight = `${minHeight}px`;
  }, [numCbsVisible]);

  const classNames = [
    styles['container'],
    'no-scrollbar',
  ];
  if (isVisible) classNames.push(styles['visible']);
  if (className) classNames.push(className);
  /**
   * Chained boards should be printed to HTML
   * in reverse order for proper Z stacking.
   */
  return (
    <div
      ref={ref}
      className={classNames.join(' ')}
      onClick={handleBlockerClick}
      onScroll={handleScroll}
    >
      <div
        ref={innerRef}
        className={styles['inner']}
      >
        {cbsRef.current.map((ref, i) => {
          return (
            <ChainedBoard
              key={i}
              ref={ref}
              boardStyles={boardStyles[i]}
              isVisible={i < numCbsVisible}
              includePlayButton={i === cbsRef.current.length - 1}
              onClickPlay={onClickPlay}
            >
              <ChainedBoardContent index={i} />
            </ChainedBoard>
          );
        }).reverse()}
      </div>
    </div>
  );
}

export default Menu;
