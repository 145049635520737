import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styles from './confirm-modal.module.scss';

import Blocker from '../blocker';
import PanelBoard from '../panel-board';
import CtaButton from '../cta-button';

export function ConfirmModal({ className, children }) {
  const modal = useModal();
  const handleConfirm = () => {
    modal.resolve();
    modal.hide();
  };
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <Blocker onClick={modal.hide}>
      <PanelBoard
        className={classNames.join(' ')}
        panelClassName={styles['panel']}
        hasDropShadow={true}
      >
        <div className={styles['content']}>
          {children}
        </div>
        <div className={styles['buttons']}>
          <CtaButton
            className={styles['button']}
            onClick={handleConfirm}
          >Do it!</CtaButton>
          <CtaButton
            className={styles['button']}
            isSecondary={true}
            onClick={modal.hide}
          >Cancel</CtaButton>
        </div>
      </PanelBoard>
    </Blocker>
  );
}

export default NiceModal.create(ConfirmModal);
