import styles from './progress.module.scss';

import Indent from 'pages/game/modal/panel/indent';

function Notch({ isVisible }) {
  const classNames = [styles['notch'], 'border-3d'];
  if (isVisible) {
    classNames.push(styles['notch--visible']);
  }
  return <div className={classNames.join(' ')}></div>;
}

function Progress({ className, value, total }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  const progress = total > 0 ? value / total : 0;
  const numNotches = 40;
  const numVisibleNotches = Math.round(progress * numNotches);
  return (
    <div className={classNames.join(' ')}>
      <div className={styles['text']}>
        Progress:&nbsp;
        <Indent className={styles['indent']}>
          <span>{value ?? '-'}</span>
        </Indent>
        /
        <Indent className={styles['indent']}>
          <span>{total ?? '-'}</span>
        </Indent>
      </div>
      <Indent className={styles['bar']}>
        {Array(numNotches).fill(null).map((e, i) => {
          return <Notch key={i} isVisible={i < numVisibleNotches}></Notch>;
        })}
        <div className={styles['bar-overlay']}>
          <span>{`${(100 * progress).toLocaleString()}%`}</span>
        </div>
      </Indent>
    </div>
  );
}


export default Progress;
