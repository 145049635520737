import { useUser } from 'hooks/query';
import { resourceNames } from 'constants';
import { requireResourceImgs } from 'game-utils';
import styles from './top-bar.module.scss';

import Dropdown from './dropdown';
import { default as DropdownOption } from './dropdown/option';
import Button from './button';
import Indent from './indent';

import coinSackImg from 'assets/images/game/hud/coin-sack.png';
import changeWalletImg from 'assets/images/game/hud/change-wallet.png';
import exitImg from 'assets/images/game/hud/exit.png';
import logoImg from 'assets/images/game/hud/logo.png';
import goldImg from 'assets/images/game/gold.png';

const resourceImgs = requireResourceImgs();

function TopBar({
  className,
  onClickConnect,
  onClickChangeWallet,
  onClickDisconnect,
}) {
  const user = useUser();
  const getConnectText = () => {
    if (!user.data) {
      return 'Connect to wallet';
    }
    const walletKey = user.data.wallet_key;
    const numChars = 4;
    return walletKey.substring(0, numChars) + '.....'
      + walletKey.substring(walletKey.length - numChars);
  };
  const handleDropdownToggleClick = () => {
    if (user.data) {
      return;
    }
    if (onClickConnect) {
      onClickConnect();
    }
  };
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <div className={styles['inner']}>
        <div className={styles['left']}>
          <Dropdown
            className={styles['button']}
            imgSrc={coinSackImg}
            imgAlt="Coin sack"
            text={getConnectText()}
            isButton={!user.data}
            onToggleClick={handleDropdownToggleClick}
          >
            <DropdownOption
              imgSrc={changeWalletImg}
              imgAlt="Change wallet"
              text="Change Wallet"
              onClick={onClickChangeWallet}
            />
            <DropdownOption
              imgSrc={exitImg}
              imgAlt="Exit"
              text="Disconnect"
              onClick={onClickDisconnect}
            />
          </Dropdown>
          <Button className={styles['button']} />
          <Button className={styles['button']} />
        </div>
        <div className={styles['center']}>
          <div className={styles['logo-container']}>
            <img
              className="contained"
              src={logoImg}
              alt="Imperium Solari"
            />
          </div>
        </div>
        <div className={styles['right']}>
          <Indent
            className={styles['indent']}
            imgSrc={goldImg}
            imgAlt="Gold"
            text={user.data ? Number(user.data.gold).toLocaleString() : 0}
          />
          {resourceNames.map(name => {
            let amount = 0;
            if (user.data) {
              amount = user.data[`res_${name}`] ?? 0;
            }
            return (
              <Indent
                key={name}
                className={styles['indent']}
                imgSrc={resourceImgs[name]}
                imgAlt={name}
                text={Number(amount).toLocaleString()}
              />
            );
          })}
        </div>
      </div>
      <hr />
    </div>
  );
}

export default TopBar;
