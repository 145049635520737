import styles from './title-plate.module.scss';

import Plate from 'pages/game/modal/plate';
import Rivet from 'pages/game/modal/rivet';

function TitlePlate({ title }) {
  return (
    <Plate className={styles['container']} hasOutline={true} >
      <div className={`${styles['plaque']} border-3d`}>
        {Array(4).fill(null).map((_, i) => {
          return <Rivet key={i} />;
        })}
        <span>{title}</span>
      </div>
    </Plate>
  );
}

export default TitlePlate;
