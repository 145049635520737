import React from 'react';
import styles from './board.module.scss';

const Board = React.forwardRef(({ className, children, onMouseEnter, onMouseLeave }, ref) => {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <div
      ref = {ref}
      className={classNames.join(' ')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
});

export default Board;
