import NiceModal, { useModal } from '@ebay/nice-modal-react';
import styles from './order-modal.module.scss';

import Blocker from 'pages/game/modal/blocker';
import Plate from 'pages/game/modal/plate';
import Panel from 'pages/game/modal/panel';
import CtaButton from 'pages/game/modal/cta-button';

function Option({ value, isSecondary, children, onClick }) {
  return (
    <Panel className={styles['option-panel']} hasCorners={false}>
      <CtaButton
        className={styles['button']}
        isSecondary={isSecondary}
        onClick={() => onClick(value)}
      >{children}</CtaButton>
    </Panel>
  )
}

function OrderModal() {
  const modal = useModal();
  const hanelClick = value => {
    modal.resolve(value);
    modal.hide();
  };
  return (
    <Blocker onClick={modal.hide}>
      <Plate
        className={styles['container']}
        hasOutline={true}
        hasDropShadow={true}
      >
        <Panel className={styles['panel']} hasOutline={false}>
          <Option
            value="buy"
            onClick={hanelClick}
          >Buy</Option>
          <Option
            value="sell"
            isSecondary={true}
            onClick={hanelClick}
          >Sell</Option>
        </Panel>
      </Plate>
    </Blocker>
  )
}

export default NiceModal.create(OrderModal);
