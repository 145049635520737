import styles from './checkbox.module.scss';

import cornerTlImg from 'assets/images/game/pool/checkbox/corner-tl.png';
import cornerTrImg from 'assets/images/game/pool/checkbox/corner-tr.png';
import cornerBrImg from 'assets/images/game/pool/checkbox/corner-br.png';
import cornerBlImg from 'assets/images/game/pool/checkbox/corner-bl.png';
import checkmarkImg from 'assets/images/game/pool/checkbox/checkmark.png';

function Corner({ imgSrc }) {
  return <img className={styles['corner']} src={imgSrc} alt="Corner" />;
}

function Corners() {
  return (
    <>
      <Corner imgSrc={cornerTlImg} />
      <Corner imgSrc={cornerTrImg} />
      <Corner imgSrc={cornerBrImg} />
      <Corner imgSrc={cornerBlImg} />
    </>
  );
}

function Checkmark() {
  return (
    <div className={styles['checkmark-container']}>
      <img className="contained" src={checkmarkImg} alt="Checkmark" />
    </div>
  );
}

function Checkbox({ className, name, isChecked, onChange }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <>
      <input
        type="radio"
        className="hidden"
        name={name}
        checked={isChecked}
        onChange={onChange}
      />
      <div className={classNames.join(' ')}>
        <Corners />
        <Checkmark />
      </div>
    </>
  );
}

export default Checkbox;
