import { useQueryClient } from '@tanstack/react-query';
import { useNftsAvailability, useNftsMint, useUser } from 'hooks/query';
import NiceModal from '@ebay/nice-modal-react';
import styles from './castle-nft-modal.module.scss';

import Modal from 'pages/game/modal';
import Panel from 'pages/game/modal/panel';
import PanelBoard from 'pages/game/modal/panel-board';
import Indent from 'pages/game/modal/panel/indent';
import Progress from '../progress';
import CtaButton from 'pages/game/modal/cta-button';

import ConfirmModal from 'pages/game/modal/confirm';
import LoadingModal from 'pages/game/modal/loading';
import ActionResultModal from 'pages/game/modal/action-result';

import characterLargeImg from 'assets/images/game/castle/character-large.png';

function ValueField({ name, value }) {
  return (
    <div className={styles['value-field']}>
      <Indent className={styles['indent']}>
        <span>Sol</span>
        <span>{Number(value).toLocaleString()}</span>
      </Indent>
      <span>{name}</span>
    </div>
  );
}

function CastleNftModal() {
  const queryClient = useQueryClient();
  const user = useUser();
  const availability = useNftsAvailability();
  const minter = useNftsMint();
  const handleClickMint = () => {
    NiceModal.show(ConfirmModal, {
      children: 'This action will mint an NFT'
    }).then(() => {
      NiceModal.show(LoadingModal);
      minter.mutate(null, {
        onSuccess: () => {
          queryClient.invalidateQueries(['user']);
          queryClient.invalidateQueries(['nfts-availability']);
          queryClient.invalidateQueries(['nfts']);
          NiceModal.show(ActionResultModal);
        },
        onError: (error) => alert(error.response.data.message),
        onSettled: () => NiceModal.hide(LoadingModal),
      });
    });
  };
  const userSol = user.data ? user.data.sol : 0;
  const priceSol = availability.data ? availability.data.price_sol : 0;
  const available = availability.data ? availability.data.available : 0;
  const total = availability.data ? availability.data.total : 0;
  return (
    <Modal title="Mint an NFT">
      <div className={styles['board-container']}>
        <PanelBoard
          className={styles['character-board']}
          panelClassName={styles['character-board-panel']}
          panelHasGleam={true}
        >
          <div className={`${styles['img-container']} shadowed-img-container`}>
            <img className="contained" src={characterLargeImg} alt="NFT" />
          </div>
        </PanelBoard>
        <PanelBoard
          className={styles['info-board']}
          hasNoPanel={true}
        >
          <Panel className={styles['value-panel']}>
            <ValueField
              name="Available Balance"
              value={userSol}
            />
            <ValueField
              name="Price"
              value={priceSol}
            />
          </Panel>
          <Panel className={styles['progress-panel']}>
            <Progress
              className={styles['progress']}
              value={total - available}
              total={total}
            />
            <CtaButton
              className={styles['mint-button']}
              isMint={true}
              onClick={handleClickMint}
              isDisabled={userSol < priceSol || available <= 0 || minter.isLoading}
            >Mint me!</CtaButton>
          </Panel>
        </PanelBoard>
      </div>
    </Modal>
  );
}

export default NiceModal.create(CastleNftModal);
