// https://jack72828383883.medium.com/how-to-preload-images-into-cache-in-react-js-ff1642708240
export const cacheImages = async (srcArr, onComplete) => {
  const promises = await srcArr.map(src => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    })
  });
  await Promise.all(promises);
  if (onComplete) {
    onComplete();
  }
};

export const assignToRefs = (obj, ...refs) => {
  refs.forEach(ref => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(obj);
      } else {
        ref.current = obj;
      }
    }
  });
};

export const roundToDec = (v, d = 0) => {
  const m = Math.pow(10, d);
  return Math.round((v + Number.EPSILON) * m) / m;
};

export const lerp = (from, to, progress) => {
  return from + (to - from) * progress;
};

export const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max);
};

export const hasOwnProperty = (obj, prop) => {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}
