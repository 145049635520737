import { useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import styles from './market-tools-modal.module.scss';

import Modal from 'pages/game/modal';
import Board from 'pages/game/modal/board';
import OrdersPanel from './orders-panel';
import ItemsPanel from './items-panel';
import Plate from 'pages/game/modal/plate';
import StatsPanel from './stats-panel';
import QuantityPanel from './quantity-panel';
import CtaButton from 'pages/game/modal/cta-button';

import ConfirmModal from 'pages/game/modal/confirm';
import OrderModal from '../order';

function MarketToolsModal() {
  const [isBuy, setIsBuy] = useState(false);
  const orderText = isBuy ? 'Buy' : 'Sell';

  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const handleItemCheckboxChange = (e, i) => {
    if (e.target.checked) {
      setSelectedItemIndex(i);
    }
  };

  const [orders, setOrders] = useState([]);
  const handleClickOrder = () => {
    NiceModal.show(ConfirmModal, {
      children: isBuy ? 
        'Are you sure you want to buy this item?' :
        'Are you sure you want to sell your item?'
    }).then(() => {
      const ordersNew = orders.slice();
      ordersNew.push(isBuy);
      setOrders(ordersNew);
      setSelectedItemIndex(null);
    });
  };

  const handleClickEmptyOrderSlot = () => {
    NiceModal.show(OrderModal).then(value => {
      const toBuy = value === 'buy';
      if (toBuy !== isBuy) {
        setSelectedItemIndex(null);
      }
      setIsBuy(toBuy);
    });
  };

  const ctaButtonClassNames = [
    styles['order-button'],
    styles[isBuy ? 'buy' : 'sell']
  ];

  return (
    <Modal title="Tools" >
      <Board className={styles['board']}>
        <OrdersPanel
          existing={orders}
          numEmptySlots={10 - orders.length}
          onClickEmptySlot={handleClickEmptyOrderSlot}
        />
        <div className={styles['right']}>
          <ItemsPanel
            isSimple={!isBuy}
            selectedIndex={selectedItemIndex}
            onItemCheckboxChange={handleItemCheckboxChange}
          />
          <Plate className={styles['plate']} hasOutline={true}>
            <StatsPanel
              isEmpty={selectedItemIndex === null}
              emptyMessage={`Select an item to ${orderText.toLowerCase()}`}
            />
            <div className={styles['plate-separator']}></div>
            <QuantityPanel />
            <CtaButton
              className={ctaButtonClassNames.join(' ')}
              isSecondary={!isBuy}
              isDisabled={selectedItemIndex === null}
              onClick={handleClickOrder}
            >{orderText}</CtaButton>
          </Plate>
        </div>
      </Board>
    </Modal>
  );
}

export default NiceModal.create(MarketToolsModal);
