import styles from './content.module.scss';

import huntingImg from 'assets/images/game/hud/menu/hunting.gif';
import miningImg from 'assets/images/game/hud/menu/mining.gif';
import woodcuttingImg from 'assets/images/game/hud/menu/woodcutting.gif';

const imgs = [
  huntingImg,
  miningImg,
  woodcuttingImg,
];

function Content({ index }) {
  return (
    <div className={styles['container']}>
      <h2>Hello Herder</h2>
      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas dui id ornare arcu odio ut sem nulla. Pellentesque dignissim enim sit amet venenatis urna cursus eget nunc. Nullam vehicula ipsum a arcu cursus vitae congue mauris. Fringilla ut morbi tincidunt augue interdum velit. Turpis egestas pretium aenean pharetra magna ac placerat vestibulum. Pretium vulputate sapien nec sagittis aliquam. Imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Aliquet nec ullamcorper sit amet. Lacinia at quis risus sed vulputate odio ut. Nibh sed pulvinar proin gravida hendrerit. Et sollicitudin ac orci phasellus egestas tellus. Arcu ac tortor dignissim convallis aenean. Nulla malesuada pellentesque elit eget gravida cum sociis natoque. Libero nunc consequat interdum varius sit amet mattis vulputate enim. Tellus rutrum tellus pellentesque eu tincidunt tortor aliquam.</span>
      <img src={imgs[index % 3]} alt="" />
    </div>
  );
}

export default Content;
