import styles from './bottom-bar.module.scss';

function BottomBar({ className }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <hr />
      <div className={styles['inner']}>
        <div className={styles['left']}></div>
        <div className={styles['right']}></div>
      </div>
    </div>
  );
}

export default BottomBar;
