import { requireToolIcons } from 'game-utils';
import styles from './info-box.module.scss';

import Panel from 'pages/game/modal/panel';
import LabelledIndent from 'pages/game/modal/panel/labelled-indent';

const toolIcons = requireToolIcons();

function InfoBox({
  className,
  isVisible,
  toolType,
  baseGatherSpeed,
  multiplier,
}) {
  baseGatherSpeed = Number(baseGatherSpeed);
  multiplier = Number(multiplier);
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <Panel
      className={classNames.join(' ')}
      hasCorners={false}
      hasBorder={false}
      isVisible={isVisible}
    >
      <LabelledIndent
        className={styles['labelled-indent']}
        label="Base gathering speed"
      >
        <div className={`${styles['indent-tool']} border-3d`}>
          <div className={styles['indent-tool-img-container']}>
            <img
              className="contained"
              src={toolIcons[toolType]}
              alt={toolType}
            />
          </div>
        </div>
        <span>{baseGatherSpeed.toLocaleString()}/s</span>
      </LabelledIndent>
      <div className={styles['labelled-indent-row']}>
        <LabelledIndent
          className={styles['labelled-indent']}
          label="Multiplier"
        >
          <span>x{multiplier.toLocaleString()}</span>
        </LabelledIndent>
        <LabelledIndent
          className={styles['labelled-indent']}
          label="Total"
        >
          <span>{(multiplier * baseGatherSpeed).toLocaleString()}/s</span>
        </LabelledIndent>
      </div>
    </Panel>
  );
}

export default InfoBox;
