import styles from './stats-panel.module.scss';

import Panel from 'pages/game/modal/panel';
import TitlePlate from '../title-plate';
import LabelledIndent from 'pages/game/modal/panel/labelled-indent';
import CtaButton from 'pages/game/modal/cta-button';

function StatsPanel({
  className,
  availableToGather,
  totalStaked,
  isGatherDisabled,
  isUnstakeDisabled,
  onClickGather,
  onClickUnstake,
}) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  return (
    <Panel className={classNames.join(' ')}>
      <TitlePlate title="Resources Stats" />
      <Panel
        className={styles['box']}
        hasCorners={false}
        hasBorder={false}
      >
        <LabelledIndent
          className={styles['labelled-indent']}
          label="Available to gather"
        >
          <span>{Number(availableToGather).toLocaleString()}</span>
        </LabelledIndent>
        <LabelledIndent
          className={styles['labelled-indent']}
          label="Total staked"
        >
          <span>{Number(totalStaked).toLocaleString()}</span>
        </LabelledIndent>
      </Panel>
      <div className={styles['buttons']}>
        <CtaButton
          className={styles['button']}
          isDisabled={isGatherDisabled}
          onClick={onClickGather}
        >Gather</CtaButton>
        <CtaButton
          className={styles['button']}
          isSecondary={true}
          isDisabled={isUnstakeDisabled}
          onClick={onClickUnstake}
        >Unstake</CtaButton>
      </div>
    </Panel>
  );
}

export default StatsPanel;
