import styles from './chain.module.scss';

const numLinks = 5; // max: 11
const linkImgs = Array(numLinks).fill(null).map((_, i) => {
  const imgIndex = Math.floor(i / 2) + 1;
  const type = i % 2 ? 'front' : 'side';
  return require(`assets/images/game/hud/menu/chain/${type}/${imgIndex}.png`);
});

function Chain({ className, linkRef }) {
  const classNames = [styles['container']];
  if (className) classNames.push(className);
  return (
    <div className={classNames.join(' ')}>
      {linkImgs.map((img, i) => {
        return (
          <img
            key={i}
            ref={linkRef}
            src={img}
            alt="Chain link"
          />
        );
      })}
    </div>
  );
}

export default Chain;
