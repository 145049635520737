import styles from './toggle.module.scss';

function Toggle({
  className,
  imgSrc,
  imgAlt,
  text,
  isChecked,
  onChange,
  styleAsButton,
  onClick,
}) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  if (styleAsButton) {
    classNames.push(styles['button']);
  }
  return (
    <label className={classNames.join(' ')}>
      <input
        type="checkbox"
        className="hidden"
        checked={isChecked}
        onChange={onChange}
        onClick={onClick}
      />
      <div className={styles['inner']}>
        <div className={styles['img-container']}>
          <img className="contained" src={imgSrc} alt={imgAlt} />
        </div>
        <span>{text}</span>
      </div>
    </label>
  );
};

export default Toggle;
