import { useRef } from 'react';
import { requireToolIcons } from 'game-utils';
import styles from './worker.module.scss';

import Character from './character';
import Checkbox from './checkbox';

const toolIcons = requireToolIcons();

function Level({ value }) {
  return (
    <div className={styles['level']}>
      <span>Lvl</span>
      <span>{value}</span>
    </div>
  );
}

function Resources({ imgSrc, imgAlt, value }) {
  return (
    <div className={styles['stat']}>
      <div className={styles['stat-img-container']}>
        <img className="contained" src={imgSrc} alt={imgAlt} />
      </div>
      <span>{Number(value).toLocaleString()}</span>
    </div>
  );
}

function Worker({
  className,
  alternateColor,
  name,
  imgSrc,
  isStaked,
  toolType,
  resources,
  level,
  canLevelUp,
  isChecked,
  onHover,
  onCheckboxChange,
}) {
  const ref = useRef(null);
  const handleHover = isHovered => {
    if (onHover) {
      onHover(isHovered, ref.current.getBoundingClientRect());
    }
  };
  const classNames = [styles['container'], 'border-3d'];
  if (alternateColor) {
    classNames.push(styles['alt-color']);
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <label
      ref={ref}
      className={classNames.join(' ')}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      <Character
        canLevelUp={canLevelUp}
        imgSrc={imgSrc}
        imgAlt={name}
      />
      <div className={styles['info']}>
        <span>{name}</span>
        {isStaked ? <Resources
          imgSrc={toolIcons[toolType]}
          imgAlt={toolType}
          value={resources}
        /> : <Level value={level} />}
      </div>
      <Checkbox
        className={styles['checkbox']}
        name={`${isStaked ? 'staked' : 'available'}-worker`}
        isChecked={isChecked}
        onChange={onCheckboxChange}
      />
    </label>
  );
}

export default Worker;
