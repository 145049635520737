import { useEffect, useRef } from 'react';
import { useWindowScale, useMousePosition } from 'hooks/util';
import { modalScale } from 'constants';
import styles from './mouse-follow.module.scss';

function MouseFollow({
  fallbackMousePosition,
  mouseOffset,
  fixedPosition,
  freezePosition,
  children
}) {
  const ref = useRef(null);
  const scale = modalScale * useWindowScale();
  const position = useMousePosition();

  useEffect(() => {
    if (ref.current &&
      !isNaN(scale) &&
      position.x >= 0 &&
      position.y >= 0 &&
      !freezePosition) {

      let pos = position;
      if (!pos.x && !pos.y && fallbackMousePosition) {
        pos = fallbackMousePosition;
      }
      if (mouseOffset) {
        pos.x += mouseOffset.x * scale;
        pos.y += mouseOffset.y * scale;
      }
      if (fixedPosition?.x) {
        pos.x = fixedPosition.x;
      }
      if (fixedPosition?.y) {
        pos.y = fixedPosition.y;
      }
      ref.current.style.top = `${(pos.y) / scale}px`;
      ref.current.style.left = `${(pos.x) / scale}px`;
    }
  }, [fallbackMousePosition, scale, position]);
  
  return (
    <div ref={ref} className={styles['container']}>
      {children}
    </div>
  );
}

export default MouseFollow;
