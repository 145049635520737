import styles from './menu.module.scss';

import rivetImg from 'assets/images/game/hud/rivet.png';

function Menu({ className, isVisible, children }) {
  const classNames = [styles['container']];
  if (className) {
    classNames.push(className);
  }
  if (isVisible) {
    classNames.push(styles['visible']);
  }
  return (
    <div className={classNames.join(' ')}>
      <div className={styles['inner']}>
        <div className={styles['options']}>
          {children}
        </div>
        <div className={styles['rivets']}>
          <img src={rivetImg} alt="Rivet" />
          <img src={rivetImg} alt="Rivet" />
          <img src={rivetImg} alt="Rivet" />
          <img src={rivetImg} alt="Rivet" />
        </div>
      </div>
    </div>
  );
}

export default Menu;
